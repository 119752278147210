import { useState, useCallback, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../components/Global/Grid/index.scss';
import './index.scss';
import Button from '../../components/Shared/Button';
import AddUser from '../../components/AddUser';
import EditUser from '../../components/EditUser';
import DeleteUser from '../../components/DeleteUser';
import Notification from '../../components/Notification';
import Loader from '../../components/Shared/Loader';

import { getAllHmUsers } from '../../services/HmUsersService';

const HmUser = () => {
  const addUserData = {
    title: 'Add user',
    content: 'User has been added!',
    action: 'hmUser',
  };
  const editUserData = {
    title: 'Edit user',
    content: 'User has been edited!',
    action: 'hmUser',
  };
  const deleteUserData = {
    title: 'Delete user',
    content: 'User has been deleted!',
    action: 'hmUser',
  };

  const [hmUsers, setHmUsers] = useState<any[]>([]);
  const [user, setUser] = useState({});
  const [showAddUser, setAddUser] = useState(false);
  const [showEditUser, setEditUser] = useState(false);
  const [showDeleteUser, setDeleteUser] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [loadedSearch, setLoadedSearch] = useState<any[]>([]);
  const userDetail = useSelector((state: RootStateOrAny) => state.user);
  const [showFailed, setShowFailed] = useState(false);
  const [notifyText, setNotifyText] = useState('');
  const [userId, setUserId] = useState('');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getHmUsersList();
  }, []);

  const getHmUsersList = () => {
    setShowLoader(true);
    getAllHmUsers()
      .then(function (data) {
        setHmUsers(data);
        setShowLoader(false);
        setLoadedSearch(data);
        if (userId) {
          const element = document.getElementById(userId);
          element?.scrollIntoView({ behavior: 'smooth' });
        }
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const submitHmUser = () => {
    if (showNotification) {
      closePopup();
    }
  };

  const closePopup = () => {
    setAddUser(false);
    setEditUser(false);
    setDeleteUser(false);
    setNotification(false);
    if (showNotification) {
      getHmUsersList();
    }
  };

  const addSuccess = useCallback((event) => {
    setUserId(event);
    closePopup();
    setNotification(true);
    setNotificationData(addUserData);
  }, []);

  const updateUser = (user: any) => {
    setUser(user);
    setEditUser(true);
  };

  const editSuccess = useCallback((event) => {
    setUserId(event);
    closePopup();
    setNotification(true);
    setNotificationData(editUserData);
  }, []);

  const deleteUser = (user: any) => {
    if (userDetail.user.username == user.email) {
      setNotifyText('You can not delete yourself.');
      setShowFailed(true);
      setTimeout(() => {
        setShowFailed(false);
      }, 5000);
    } else {
      setUser(user);
      setDeleteUser(true);
    }
  };

  const deleteSuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(deleteUserData);
  }, []);

  const onInputchange = (event: any) => {
    setSearchValue(event.target.value);
    if (event.target.value.length < 3) {
      setSearchWord('');
      setHmUsers(loadedSearch);
    } else {
      if (event.target.value.length > 3) {
        // console.log("in search", loadedSearch)
        let searchList = loadedSearch.filter((u) => u.firstName.toLowerCase().includes(event.target.value));
        if (searchList.length === 0) {
          searchList = loadedSearch.filter((u) => u.firstName.includes(event.target.value));
        }

        if (searchList.length === 0) {
          searchList = loadedSearch.filter((u) => u.lastName.includes(event.target.value));
        }
        if (searchList.length === 0) {
          searchList = loadedSearch.filter((u) => u.lastName.toLowerCase().includes(event.target.value));
        }

        if (searchList.length === 0) {
          searchList = loadedSearch.filter((user) => user.email.toLowerCase().includes(event.target.value));
        }
        setHmUsers(searchList);
      }
    }
  };

  const onInputClear = () => {
    setSearchValue('');
    setSearchWord('');
    setHmUsers(loadedSearch);
  };

  return (
    <div className="hmusers-page pb-3">
      <Container fluid="xl">
        <Row>
          <Col xs={12} sm={12} md={12}>
            <h2 className="mt-4  Heading">H&M Group Users</h2>

            <div className="users-table">
              <Row>
                <Col xs={6} sm={6} md={6}>
                  <div className="right-inner-addon">
                    {searchValue && <i className="icon-Delete-circle" onClick={onInputClear}></i>}
                    <input
                      style={{ marginBottom: 20 }}
                      className={`form-control`}
                      value={searchValue}
                      placeholder="Search for users"
                      onChange={onInputchange}
                    />
                  </div>
                </Col>
              </Row>

              <table className="table">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {hmUsers &&
                    hmUsers
                      ?.sort((a, b) => a.firstName.localeCompare(b.firstName, 'sv'))
                      ?.map((user) => {
                        return (
                          <tr key={user.userId} id={user.userId} className={userId === user.userId ? 'userActive' : ''}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td className="u-textRight">
                              <Button
                                className="Button Button--round Button--small Button--dawnPink mr-2 Button--title"
                                data-title="Edit user"
                                onClick={() => {
                                  updateUser(user);
                                }}
                              >
                                <i className="icon-edit-write"></i>
                              </Button>
                              <Button
                                className="Button Button--round Button--small Button--red Button--title"
                                data-title="Delete user"
                                onClick={() => {
                                  deleteUser(user);
                                }}
                              >
                                <i className="icon-Close"></i>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>

            <div className="add-users text-center">
              <Button
                className="Button Button--whiteBlack"
                onClick={() => {
                  setAddUser(!showAddUser);
                }}
                data-testid="addNewUser"
              >
                {' '}
                Add new user
              </Button>
              {/* <Button className="Button Button--round Button--plus" onClick={() => {getAllHmUsers()}} /> */}
            </div>

            {showAddUser ? <AddUser closePopup={closePopup} addSuccess={addSuccess} /> : ''}
            {showEditUser ? <EditUser user={user} closePopup={closePopup} editSuccess={editSuccess} /> : ''}
            {showDeleteUser ? <DeleteUser user={user} closePopup={closePopup} deleteSuccess={deleteSuccess} /> : ''}
            {showNotification ? (
              <Notification data={notificationData} closePopup={closePopup} getHmUsersList={submitHmUser} />
            ) : (
              ''
            )}
            {showLoader ? <Loader /> : ''}
            {showFailed ? (
              <div className="alert alert-danger show-failed">
                <strong>Failed!</strong> {notifyText}
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HmUser;
