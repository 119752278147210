const Data = [
  {
    id: 1,
    text: 'Clothing',
    checked: false,
    hasChildren: true,
    children: [
      {
        id: 2,
        text: 'Bodies',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 3,
            text: 'Body',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 4,
            text: 'Baby bodysuit',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 5,
        text: 'Dresses & tunics & kaftans',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 6,
            text: 'Caftan',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 7,
            text: 'Dress',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 8,
            text: 'Sari',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 9,
            text: 'Tunic',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 10,
        text: 'Nightwear',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 11,
            text: 'Dressing gown',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 12,
            text: 'Nightdress',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 13,
            text: 'Night shirt',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 14,
            text: 'Pyjamas',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 15,
            text: 'Pyjamasjumpsuit',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 16,
        text: 'Outerwear',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 17,
            text: 'Mens Coat',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 18,
            text: 'Jacket',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 19,
            text: 'Womens Coat',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 20,
        text: 'Overalls & jumpsuits',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 21,
            text: 'All-in-one suit',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 22,
            text: 'Boiler suit',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 23,
            text: 'Costume',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 24,
            text: 'Jumpsuit',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 25,
            text: 'Playsuit',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 26,
        text: 'Shirts & blouses',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 27,
            text: 'Blouse',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 28,
            text: 'Shirt',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 29,
        text: 'Shorts & skirts',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 30,
            text: 'Shorts',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 31,
            text: 'Skirt',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 32,
        text: 'Suit jacket & vests',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 33,
            text: 'Gilet',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 34,
            text: 'Jacket',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 35,
            text: 'Waistcoat',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 36,
        text: 'Swimwear & underwear',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 37,
            text: 'Base layer set',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 38,
            text: 'Bikini bottoms',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 39,
            text: 'Bikini top',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 40,
            text: 'Bra',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 41,
            text: 'Bra accessories',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 42,
            text: 'Long johns',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 43,
            text: 'Mens Briefs',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 44,
            text: 'Puff pants',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 45,
            text: 'Socks',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 46,
            text: 'Swim shorts',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 47,
            text: 'Swim skirt',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 48,
            text: 'Swimsuit',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 49,
            text: 'Swim top',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 50,
            text: 'Underdress',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 51,
            text: 'Wetsuit',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 52,
            text: 'Womens Briefs',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 53,
        text: 'Tights & leggings',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 54,
            text: 'Leggings',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 55,
            text: 'Tights',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 56,
        text: 'Tops, jumpers & cardigans',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 57,
            text: 'Bolero',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 58,
            text: 'Bustier',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 59,
            text: 'Cardigan',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 60,
            text: 'Corset',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 61,
            text: 'Knitted jumper',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 62,
            text: 'Poncho',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 63,
            text: 'Sweatshirt',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 64,
            text: 'Top',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 65,
            text: 'T-shirt',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 66,
        text: 'Trousers & jeans',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 67,
            text: 'Jeans',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 68,
            text: 'Trousers',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 69,
        text: 'Underwear accessories',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 70,
            text: 'Belly band',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 71,
            text: 'Clothing tape',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 72,
            text: 'Leg warmers',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 73,
            text: 'Suspender belt',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 74,
    text: 'Footwear',
    checked: false,
    hasChildren: true,
    children: [
      {
        id: 75,
        text: 'Moccasins',
        checked: false,
        hasChildren: false,
        children: [],
      },
      {
        id: 76,
        text: 'Shoe Care',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 77,
            text: 'Shoe cleaning',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 78,
            text: 'Shoehorn',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 79,
            text: 'Shoetree',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 80,
        text: 'Shoe decoration',
        checked: false,
        hasChildren: false,
        children: [],
      },
      {
        id: 81,
        text: 'Shoelaces',
        checked: false,
        hasChildren: false,
        children: [],
      },
      {
        id: 82,
        text: 'Shoes',
        checked: false,
        hasChildren: false,
        children: [],
      },
      {
        id: 83,
        text: 'Slippers',
        checked: false,
        hasChildren: false,
        children: [],
      },
    ],
  },
  {
    id: 84,
    text: 'Accessories',
    checked: false,
    hasChildren: true,
    children: [
      {
        id: 85,
        text: 'Bags',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 86,
            text: 'Bag',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 87,
        text: 'Belts',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 88,
            text: 'Belt',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 89,
        text: 'Clothing accessories',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 90,
            text: 'Arm sleeve',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 91,
            text: 'Bib style neck warmer',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 92,
            text: 'Bow tie',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 93,
            text: 'Braces',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 94,
            text: 'Collar',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 95,
            text: 'Cufflinks',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 96,
            text: 'Gaiters',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 97,
        text: 'Gloves & mittens',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 98,
            text: 'Arm warmers',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 99,
            text: 'Gloves',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 100,
            text: 'Mittens',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 101,
        text: 'Headwear',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 102,
            text: 'Beret',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 103,
            text: 'Cap',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 104,
            text: 'Ear muffs',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 105,
            text: 'Hat',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 106,
            text: 'Knitted hat',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 107,
            text: 'Sun shield',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        id: 108,
        text: 'Jewellery & watches',
        checked: false,
        hasChildren: true,
        children: [
          {
            id: 109,
            text: 'Body jewellery',
            checked: false,
            hasChildren: false,
            children: [],
          },
          {
            id: 110,
            text: 'Ring',
            checked: false,
            hasChildren: false,
            children: [],
          },
        ],
      },
    ],
  },
];
export default Data;
