import React, { useEffect, useState } from 'react';
import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const TwoColumnBlock = (props: any) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = props.block.data?.text;
    const anchorTags = tempElement.querySelectorAll('a');

    let modifiedContent = props.block.data?.text;

    anchorTags.forEach((anchor) => {
      if (anchor.origin === window.location.origin) {
        if (anchor.pathname.includes('/learn/') || anchor.pathname.includes('/sustainability/')) {
          const newUrl = `/article${anchor.pathname}`;
          modifiedContent = modifiedContent.replace(anchor.pathname, newUrl);
        }
      }
    });

    setHtmlContent(modifiedContent);
  }, [props.block.data?.text]);
  let imageToTheLeft = '';
  if (props.block.data?.imageLeftSide) {
    imageToTheLeft = ' Article-content--right';
  }

  const hasSubHeading = props.block.data?.subheading ? true : false;

  return (
    <>
      <div
        className={'Article-content Article-content--size1of2' + imageToTheLeft}
        id={props.block.data?.id}
        data-testid="TwoColumnBlock"
      >
        <Row>
          <Col lg={props.block.data?.image ? 5 : 12}>
            {!props.accordion && (
              <h3 className={`Heading ${!hasSubHeading ? 'u-marginBlg' : ''}`}>{props.block.data?.heading}</h3>
            )}
            {hasSubHeading && <h5 className="Heading Article-subHeading">{props.block.data?.subheading}</h5>}
            <div
              dangerouslySetInnerHTML={{
                __html: htmlContent,
              }}
            />
          </Col>
          {props.block.data?.image && (
            <Col lg={5}>
              <div className="Article-image">
                <img src={props.block.data?.image} />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};
export default TwoColumnBlock;
