import { useState, useRef, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';
import Button from '../Shared/Button';
import Loader from '../../components/Shared/Loader';
import { uploadCampaign } from '../../services/CampaignService';
import fileImage from '../../assets/images/fileImage.png';

const ProductAdd = (props: any) => {
  const [selectedFileData, setSelectedFileData] = useState<any>(null);
  const [image, setImage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const inputFile: any = useRef(null);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const selectedAssetTypeValue = props.selectedAssetTypeValue;
  const selectedFormatValue = props.selectedFormatValue;
  const isButtonDisabled = !selectedAssetTypeValue || (selectedAssetTypeValue === 'Brand' && !selectedFormatValue);

  useEffect(() => {
    setImage('');
    props.onFileError('');
    props.onFileSelect(null);
  }, [selectedAssetTypeValue, selectedFormatValue]);

  const uploadFile = () => {
    inputFile.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedFileData(selectedFile);
      const fileType = selectedFile.type;
      const validFileTypes = getValidFileTypes(selectedAssetTypeValue);

      if (validFileTypes.includes(fileType)) {
        if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          setImage(fileImage);
          props.onFileError('');
          props.onFileSelect(selectedFile);
        } else {
          const img = new Image();
          img.src = URL.createObjectURL(selectedFile);

          img.onload = () => {
            let valid = true;
            if (selectedAssetTypeValue === 'Brand') {
              if (selectedFormatValue === 'Desktop') {
                valid = validateDesktopImage(img.width, img.height);
                if (!valid) {
                  props.onFileError(
                    `This asset doesn’t fit the “Desktop (16:9)” format type selected. Please change the “Format” field or upload an asset that has Desktop (16:9) dimensions. Min size: 1600 x 900 pixels Max size: 2590 x 1456 pixels`,
                  );
                }
              } else if (selectedFormatValue === 'Mobile') {
                valid = validateMobileImage(img.width, img.height);
                if (!valid) {
                  props.onFileError(
                    `This asset doesn’t fit the “Mobile (4:5)” format type selected. Please change the “Format” field or upload an asset that has Mobile (4:5) format. Min size: 400 x 500 pixels Max size: 767 x 958 pixels`,
                  );
                }
              }
            }

            if (valid) {
              setImage(URL.createObjectURL(selectedFile));
              props.onFileError('');
              props.onFileSelect(selectedFile);
            } else {
              setImage(URL.createObjectURL(selectedFile));
              // props.onFileError('Invalid image dimensions or aspect ratio.');
            }
          };
        }
      } else {
        setImage('');
        displayErrorMessage(selectedAssetTypeValue);
      }
    } else {
      setImage('');
      props.onFileError('No file selected.');
    }
  };

  const displayErrorMessage = (assetType: string) => {
    switch (assetType) {
      case 'Brand':
        props.onFileError(
          'This asset doesn’t fit the “Brand (.jpg, .png or .gif)” format type selected. Please upload accordingly.',
        );
        break;
      case 'Copy':
        props.onFileError(
          'This asset doesn’t fit the “Copy (.xlsx)” format type selected. Please upload an Excel file.',
        );
        break;
      case 'Logo':
        props.onFileError('This asset doesn’t fit the “Logo (.svg)” format type selected. Please upload an svg file.');
        break;
      default:
        props.onFileError('Selected file is not a supported format.');
    }
  };

  const validateDesktopImage = (width, height) => {
    const aspectRatio = width / height;
    return (
      aspectRatio.toFixed(2) === (16 / 9).toFixed(2) &&
      width >= 1600 &&
      height >= 900 &&
      width <= 2590 &&
      height <= 1456
    );
  };

  const validateMobileImage = (width, height) => {
    const aspectRatio = width / height;
    return (
      aspectRatio.toFixed(2) === (4 / 5).toFixed(2) && width >= 400 && height >= 500 && width <= 767 && height <= 958
    );
  };

  const getValidFileTypes = (assetType) => {
    switch (assetType) {
      case 'Brand':
        return ['image/jpeg', 'image/png', 'image/gif'];
      case 'Copy':
        return ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      case 'Logo':
        return ['image/svg+xml'];
      default:
        return [];
    }
  };

  const getAcceptAttribute = () => {
    switch (selectedAssetTypeValue) {
      case 'Brand':
        return '.jpg, .jpeg, .png, .gif';
      case 'Copy':
        return '.xlsx';
      case 'Logo':
        return '.svg';
      default:
        return '';
    }
  };

  const handleFileUpload = (e: any) => {
    let partnerId: any = '';
    if (Object.keys(partnerDetail.partner).length !== 0) {
      partnerId = partnerDetail.partner.partnerId;
    } else {
      partnerId = window.sessionStorage.getItem('partnerId');
    }
    setShowLoader(true);
    const formData = new FormData();

    Array.from(e.target.files).forEach((file: any) => {
      formData.append('file', file);
    });

    uploadCampaign(formData)
      .then((response) => {
        if (response.length !== 0) {
          setShowFailed(true);
          setTimeout(() => {
            setShowFailed(false);
          }, 5000);
        }
        setShowLoader(false);
        props.getUploadCampaigns();
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
      });
  };

  return (
    <>
      <div className={props.className}>
        <div className="ProductAdd-content">
          {image && (
            <>
              <div>
                <img
                  src={image}
                  alt="Uploaded"
                  style={{ maxWidth: '100%', maxHeight: '159px', marginBottom: '10px' }}
                />
              </div>
              <p>{selectedFileData && selectedFileData.name}</p>
            </>
          )}

          {!image && <h6>{props.uploadtext}</h6>}
          <Button
            className="Button Button--round Button--plus  Button--whiteBlack"
            onClick={uploadFile}
            data-testid="addFile"
            disabled={isButtonDisabled}
          />
        </div>
        <input
          type="file"
          id="file"
          accept={getAcceptAttribute()}
          ref={inputFile}
          onChange={handleImageChange}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          style={{ visibility: 'hidden' }}
        />
        {showLoader && <Loader />}
        {showFailed && (
          <div className="alert alert-danger show-failed">
            <strong>Failed!</strong> You have uploaded an invalid image.
          </div>
        )}
      </div>
    </>
  );
};

export default ProductAdd;
