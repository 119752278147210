import { useState, useMemo, useEffect, useCallback } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductCard from '../../components/ProductCard';
import './index.scss';
import '../../components/Upload/index.scss';
import Loader from '../../components/Shared/Loader';
import CampaignFilter from '../../components/CampaignFilter';
import CampaignDownload from '../../components/CampaignDownload';
import { getCampaigns, downloadAssets, updateAssets } from '../../services/CampaignInternalService';
import Button from '../../components/Shared/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Select from 'react-select';
import { getUniqueArray } from '../../helpers/utilities';
import DownIcon from '../../assets/images/DownIcon.png';
import UpIcon from '../../assets/images/UpIcon.png';
import rejectIcon from '../../assets/images/rejectIcon.png';
import acceptIcon from '../../assets/images/acceptIcon.png';
import rejectIconHover from '../../assets/images/rejectIconHover.png';
import acceptIconHover from '../../assets/images/acceptIconHover.png';
import PopUp from '../../components/PopUp';
import approveIcon from '../../assets/images/icons/approveIcon.png';
import removeIcon from '../../assets/images/icons/removeIcon.png';
import { customStyles, CustomClearIndicator, CustomDropdownIndicator } from '../../helpers/utilities';
import PermissionsGate from '../../components/PermissionsGate';
import { SCOPES } from '../../helpers/permission-maps';

const ApproveCampaign = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const [pendingAssetTypeList, setPendingAssetTypeList] = useState<any[]>([]);
  const [selectedPendingAssetType, setSelectedPendingAssetType] = useState<any>([]);
  const [pendingBrandList, setPendingBrandList] = useState<any[]>([]);
  const [selectedPendingBrand, setSelectedPendingBrand] = useState<any>([]);
  const [pendingFormatList, setPendingFormatList] = useState<any[]>([]);
  const [selectedPendingFormat, setSelectedPendingFormat] = useState<any>([]);
  const [pendingMarketList, setPendingMarketList] = useState<any[]>([]);
  const [selectedPendingMarket, setSelectedPendingMarket] = useState<any>([]);
  const [pendingConceptList, setPendingConceptList] = useState<any[]>([]);
  const [selectedPendingConcept, setSelectedPendingConcept] = useState<any>([]);
  const [approvedAssetTypeList, setApprovedAssetTypeList] = useState<any[]>([]);
  const [selectedApprovedAssetType, setSelectedApprovedAssetType] = useState<any>([]);
  const [approvedBrandList, setApprovedBrandList] = useState<any[]>([]);
  const [selectedApprovedBrand, setSelectedApprovedBrand] = useState<any>([]);
  const [approvedFormatList, setApprovedFormatList] = useState<any[]>([]);
  const [selectedApprovedFormat, setSelectedApprovedFormat] = useState<any>([]);
  const [approvedMarketList, setApprovedMarketList] = useState<any[]>([]);
  const [selectedApprovedMarket, setSelectedApprovedMarket] = useState<any>([]);
  const [approvedConceptList, setApprovedConceptList] = useState<any[]>([]);
  const [selectedApprovedConcept, setSelectedApprovedConcept] = useState<any>([]);
  const [rejectedAssetTypeList, setRejectedAssetTypeList] = useState<any[]>([]);
  const [selectedRejectedAssetType, setSelectedRejectedAssetType] = useState<any>([]);
  const [rejectedBrandList, setRejectedBrandList] = useState<any[]>([]);
  const [selectedRejectedBrand, setSelectedRejectedBrand] = useState<any>([]);
  const [rejectedFormatList, setRejectedFormatList] = useState<any[]>([]);
  const [selectedRejectedFormat, setSelectedRejectedFormat] = useState<any>([]);
  const [rejectedMarketList, setRejectedMarketList] = useState<any[]>([]);
  const [selectedRejectedMarket, setSelectedRejectedMarket] = useState<any>([]);
  const [rejectedConceptList, setRejectedConceptList] = useState<any[]>([]);
  const [selectedRejectedConcept, setSelectedRejectedConcept] = useState<any>([]);
  const [pendingShowAll, setPendingShowAll] = useState(false);
  const [approvedShowAll, setApprovedShowAll] = useState(false);
  const [rejectedShowAll, setRejectedShowAll] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState({});
  const [showFailed, setShowFailed] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [pendingApprovalAssests, setPendingApprovalAssests] = useState<any>([]);
  const [approvedAssests, setApprovedAssests] = useState<any>([]);
  const [rejectedAssests, setRejectedAssests] = useState<any>([]);
  const [confirmApprovePopUp, setConfirmApprovePopUp] = useState(false);
  const [selectedBrandName, setSelectedBrandName] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCountValue, setSelectedCountValue] = useState(0);
  const [approvalConfirmationPopUp, setApprovalConfirmationPopUp] = useState(false);
  const [rejectionConfirmationPopUp, setRejectionConfirmationPopUp] = useState(false);
  const [rejectReasonValue, setRejectReasonValue] = useState('');
  const [rejectReasonError, setRejectReasonError] = useState(false);
  const [rejectReasonPopUp, setRejectReasonPopUp] = useState(false);
  const [rejectReasonSubmitPopUp, setRejectReasonSubmitPopUp] = useState(false);
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [predefinedReasonsList, setPredefinedReasonsList] = useState<any[]>([]);
  const isButtonDisabled = !(selectedReason?.value || rejectReasonValue);
  const predefinedReasonValue = selectedReason?.value != undefined ? selectedReason?.value : '';
  const rejectReasonFinalValue = predefinedReasonValue
    ? predefinedReasonValue + '.' + ' ' + rejectReasonValue
    : rejectReasonValue;
  const rejectReasonTextLength = 200;

  const handleSelectChange = (selectedOption) => {
    setSelectedReason(selectedOption);
    // setRejectReasonValue(selectedOption?.value === 'custom' ? '' : selectedOption?.value);
  };

  const filterAssets = (
    assets: any[],
    selectedAssetType,
    selectedBrand,
    selectedFormat,
    selectedMarket,
    selectedConcept,
  ) => {
    let filteredAssets = assets;

    if (selectedAssetType?.label) {
      filteredAssets = filteredAssets
        .map((brand) => ({
          ...brand,
          assets: brand.assets.filter((asset) => asset.assetType === selectedAssetType.label),
        }))
        .filter((brand) => brand.assets.length > 0);
    }

    if (selectedBrand?.label) {
      filteredAssets = filteredAssets
        .map((brand) => ({
          ...brand,
          assets: brand.assets.filter((asset) => asset.brand === selectedBrand.label),
        }))
        .filter((brand) => brand.assets.length > 0);
    }

    if (selectedFormat?.label) {
      filteredAssets = filteredAssets
        .map((brand) => ({
          ...brand,
          assets: brand.assets.filter((asset) => asset.format === selectedFormat.label),
        }))
        .filter((brand) => brand.assets.length > 0);
    }

    if (selectedMarket?.label) {
      filteredAssets = filteredAssets
        .map((brand) => ({
          ...brand,
          assets: brand.assets.filter((asset) => asset.market === selectedMarket.label),
        }))
        .filter((brand) => brand.assets.length > 0);
    }

    if (selectedConcept?.label) {
      filteredAssets = filteredAssets
        .map((brand) => ({
          ...brand,
          assets: brand.assets.filter((asset) => asset.concept === selectedConcept.label),
        }))
        .filter((brand) => brand.assets.length > 0);
    }

    return filteredAssets
      ?.map((brandData) => ({
        ...brandData,
        assets: brandData.assets.sort((a, b) => new Date(b?.uploadedOn).getTime() - new Date(a?.uploadedOn).getTime()),
      }))
      .sort((a, b) => a?.brand?.localeCompare(b?.brand));
  };

  const finalPendingAssets = useMemo(
    () =>
      filterAssets(
        pendingApprovalAssests,
        selectedPendingAssetType,
        selectedPendingBrand,
        selectedPendingFormat,
        selectedPendingMarket,
        selectedPendingConcept,
      ),
    [
      pendingApprovalAssests,
      selectedPendingAssetType,
      selectedPendingBrand,
      selectedPendingFormat,
      selectedPendingMarket,
      selectedPendingConcept,
    ],
  );

  const pendingBrandsToShow = pendingShowAll ? finalPendingAssets : finalPendingAssets?.slice(0, 2);

  const finalApprovedAssets = useMemo(
    () =>
      filterAssets(
        approvedAssests,
        selectedApprovedAssetType,
        selectedApprovedBrand,
        selectedApprovedFormat,
        selectedApprovedMarket,
        selectedApprovedConcept,
      ),
    [
      approvedAssests,
      selectedApprovedAssetType,
      selectedApprovedBrand,
      selectedApprovedFormat,
      selectedApprovedMarket,
      selectedApprovedConcept,
    ],
  );

  const approvedBrandsToShow = approvedShowAll ? finalApprovedAssets : finalApprovedAssets?.slice(0, 2);

  const finalRejectedAssets = useMemo(
    () =>
      filterAssets(
        rejectedAssests,
        selectedRejectedAssetType,
        selectedRejectedBrand,
        selectedRejectedFormat,
        selectedRejectedMarket,
        selectedRejectedConcept,
      ),
    [
      rejectedAssests,
      selectedRejectedAssetType,
      selectedRejectedBrand,
      selectedRejectedFormat,
      selectedRejectedMarket,
      selectedRejectedConcept,
    ],
  );

  const rejectedBrandsToShow = rejectedShowAll ? finalRejectedAssets : finalRejectedAssets?.slice(0, 2);

  const togglePendingShowAll = () => {
    setPendingShowAll(!pendingShowAll);
    clearSelectedAssets();
  };

  const toggleApprovedShowAll = () => {
    setApprovedShowAll(!approvedShowAll);
    clearSelectedAssets();
  };

  const toggleRejectedShowAll = () => {
    setRejectedShowAll(!rejectedShowAll);
    clearSelectedAssets();
  };

  useEffect(() => {
    getCampaignsList();
  }, []);

  const getCampaignsList = () => {
    setShowLoader(true);
    getCampaigns()
      .then(function (data) {
        let assetsData = data?.assets;
        const transformedPredefinedRejectReason = data?.returnReasons?.map((reason) => ({
          value: reason,
          label: reason,
        }));
        setPredefinedReasonsList(transformedPredefinedRejectReason);

        const groupedAssets: any = [];

        assetsData.forEach((asset) => {
          const brand = asset.brand;

          const existingBrand = groupedAssets.find((item) => item?.brand === brand);

          if (existingBrand) {
            existingBrand.assets.push(asset);
          } else {
            groupedAssets.push({
              brand: brand,
              assets: [asset],
            });
          }
        });

        const pendingAssets = groupedAssets
          .map((brand) => ({
            ...brand,
            assets: (brand.assets || []).filter((asset) => asset.status === 'Received'),
          }))
          .filter((brand) => brand.assets.length > 0);

        const approvedAssets = groupedAssets
          .map((brand) => ({
            ...brand,
            assets: (brand.assets || []).filter((asset) => asset.status === 'Approved'),
          }))
          .filter((brand) => brand.assets.length > 0);

        const rejectedAssets = groupedAssets
          .map((brand) => ({
            ...brand,
            assets: (brand.assets || []).filter((asset) => asset.status === 'Rejected'),
          }))
          .filter((brand) => brand.assets.length > 0);

        setPendingApprovalAssests(pendingAssets);
        setApprovedAssests(approvedAssets);
        setRejectedAssests(rejectedAssets);

        const pendingAssetsList = assetsData?.filter((asset) => asset.status === 'Received');
        const approvedAssetsList = assetsData?.filter((asset) => asset.status === 'Approved');
        const rejectedAssetsList = assetsData?.filter((asset) => asset.status === 'Rejected');

        const pendingAssetTypes = getUniqueArray(pendingAssetsList, 'assetType');
        const pendingBrands = getUniqueArray(pendingAssetsList, 'brand');
        const pendingFormats = getUniqueArray(pendingAssetsList, 'format');
        const pendingMarkets = getUniqueArray(pendingAssetsList, 'market');
        const pendingConcepts = getUniqueArray(pendingAssetsList, 'concept');
        setPendingAssetTypeList(pendingAssetTypes);
        setPendingBrandList(pendingBrands);
        setPendingFormatList(pendingFormats);
        setPendingMarketList(pendingMarkets);
        setPendingConceptList(pendingConcepts);

        const approvedAssetTypes = getUniqueArray(approvedAssetsList, 'assetType');
        const approvedBrands = getUniqueArray(approvedAssetsList, 'brand');
        const approvedFormats = getUniqueArray(approvedAssetsList, 'format');
        const approvedMarkets = getUniqueArray(approvedAssetsList, 'market');
        const approvedConcepts = getUniqueArray(approvedAssetsList, 'concept');
        setApprovedAssetTypeList(approvedAssetTypes);
        setApprovedBrandList(approvedBrands);
        setApprovedFormatList(approvedFormats);
        setApprovedMarketList(approvedMarkets);
        setApprovedConceptList(approvedConcepts);

        const rejectedAssetTypes = getUniqueArray(rejectedAssetsList, 'assetType');
        const rejectedBrands = getUniqueArray(rejectedAssetsList, 'brand');
        const rejectedFormats = getUniqueArray(rejectedAssetsList, 'format');
        const rejectedMarkets = getUniqueArray(rejectedAssetsList, 'market');
        const rejectedConcepts = getUniqueArray(rejectedAssetsList, 'concept');
        setRejectedAssetTypeList(rejectedAssetTypes);
        setRejectedBrandList(rejectedBrands);
        setRejectedFormatList(rejectedFormats);
        setRejectedMarketList(rejectedMarkets);
        setRejectedConceptList(rejectedConcepts);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const closePopup = () => {
    setShowFilter(false);
    setShowDownload(false);
  };

  const handlePendingAssetType = (selectedOption: any) => {
    setSelectedPendingAssetType(selectedOption);
  };

  const handlePendingBrand = (selectedOption: any) => {
    setSelectedPendingBrand(selectedOption);
  };

  const handlePendingFormat = (selectedOption: any) => {
    setSelectedPendingFormat(selectedOption);
  };

  const handlePendingMarket = (selectedOption: any) => {
    setSelectedPendingMarket(selectedOption);
  };

  const handlePendingConcept = (selectedOption: any) => {
    setSelectedPendingConcept(selectedOption);
  };

  const handleApprovedAssetType = (selectedOption: any) => {
    setSelectedApprovedAssetType(selectedOption);
  };

  const handleApprovedBrand = (selectedOption: any) => {
    setSelectedApprovedBrand(selectedOption);
  };

  const handleApprovedFormat = (selectedOption: any) => {
    setSelectedApprovedFormat(selectedOption);
  };

  const handleApprovedMarket = (selectedOption: any) => {
    setSelectedApprovedMarket(selectedOption);
  };

  const handleApprovedConcept = (selectedOption: any) => {
    setSelectedApprovedConcept(selectedOption);
  };

  const handleRejectedAssetType = (selectedOption: any) => {
    setSelectedRejectedAssetType(selectedOption);
  };

  const handleRejectedBrand = (selectedOption: any) => {
    setSelectedRejectedBrand(selectedOption);
  };

  const handleRejectedFormat = (selectedOption: any) => {
    setSelectedRejectedFormat(selectedOption);
  };

  const handleRejectedMarket = (selectedOption: any) => {
    setSelectedRejectedMarket(selectedOption);
  };

  const handleRejectedConcept = (selectedOption: any) => {
    setSelectedRejectedConcept(selectedOption);
  };

  const handleAssetSelect = (campaignId, assetId) => {
    setSelectedAssets((prev) => {
      const updated = { ...prev };
      if (!updated[campaignId]) {
        updated[campaignId] = new Set();
      }
      if (updated[campaignId].has(assetId)) {
        updated[campaignId].delete(assetId);
      } else {
        updated[campaignId].add(assetId);
      }
      return updated;
    });
  };

  const handleAccept = (brand, status) => {
    const selectedIds = Array.from(selectedAssets[brand]).toString();

    let userName: any = '';
    if (user.user.username) {
      userName = user.user.username;
    } else {
      userName = window.sessionStorage.getItem('userEmail');
    }

    setShowLoader(true);
    updateAssets(selectedIds, status, userName, rejectReasonFinalValue.trim())
      .then(function (data) {
        getCampaignsList();
        setShowLoader(false);
        // setSentSuccess(true);
        // setTimeout(() => {
        //   setSentSuccess(false);
        // }, 5000);
        if (status === 'Approved') {
          approvalConfirmation();
        } else {
          rejectionConfirmation();
        }
        setRejectReasonValue('');
        setSelectedReason(null);
        setSelectedAssets((prev) => {
          const updated = { ...prev };
          updated[brand] = new Set();
          return updated;
        });
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  // const handleDownload = (asset) => {
  //   setShowLoader(true);
  //   const assetFileName = asset?.partnerId + '/' + asset?.uri;
  //   downloadAssets(assetFileName)
  //     .then(function (data) {
  //       const url = window.URL.createObjectURL(new Blob([data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       let fileName: string = asset.uniqueFileName;
  //       link.setAttribute('download', fileName);
  //       document.body.appendChild(link);
  //       link.click();
  //       if (link.parentNode) {
  //         link.parentNode.removeChild(link);
  //       }
  //       window.URL.revokeObjectURL(url);
  //       setShowLoader(false);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setShowLoader(false);
  //     });
  // };

  const handleExpand = (campaignId, assetId) => {
    clearSelectedAssets();
    handleAssetSelect(campaignId, assetId);
  };

  const clearSelectedAssets = () => {
    setSelectedAssets({});
  };

  const handleConfirmApprove = () => {
    handleAccept(selectedBrandName, selectedStatus);
    setConfirmApprovePopUp(false);
  };

  const handleConfirmApproveClose = () => {
    setConfirmApprovePopUp(false);
  };

  const confirmApprove = (brand, status, selectedCount) => {
    setSelectedCountValue(selectedCount);
    setSelectedStatus(status);
    setSelectedBrandName(brand);
    setConfirmApprovePopUp(true);
  };

  const handleApprovalConfirmationClose = () => {
    setApprovalConfirmationPopUp(false);
  };

  const approvalConfirmation = () => {
    setApprovalConfirmationPopUp(true);
  };

  const handleRejectionConfirmationClose = () => {
    setRejectionConfirmationPopUp(false);
  };

  const rejectionConfirmation = () => {
    setRejectionConfirmationPopUp(true);
  };

  const handleComments = (event: any) => {
    const { value } = event.target;
    // if (value) {
    //   setRejectReasonError(false);
    // } else {
    //   setRejectReasonError(true);
    // }
    setRejectReasonValue(value);
  };

  const handleContinue = () => {
    // setRejectReasonError(false);
    setRejectReasonPopUp(false);
    setRejectReasonSubmitPopUp(true);
  };

  const rejectReason = (brand, status) => {
    setSelectedStatus(status);
    setSelectedBrandName(brand);
    setRejectReasonPopUp(true);
  };

  const handleRejectReasonClose = () => {
    setRejectReasonPopUp(false);
    setRejectReasonValue('');
    // setRejectReasonError(false);
    setSelectedReason(null);
  };

  const handleRejectReasonSubmitClose = () => {
    setRejectReasonSubmitPopUp(false);
    setRejectReasonValue('');
    // setRejectReasonError(false);
    setSelectedReason(null);
  };

  const handleRejectReason = () => {
    handleAccept(selectedBrandName, selectedStatus);
    setRejectReasonSubmitPopUp(false);
  };

  return (
    <PermissionsGate scopes={[SCOPES.canApproveCampaigns]} redirect={true} canApproveCampaigns={true}>
      <div className="uploadCampaign hmView">
        <Container fluid="xl">
          <h2 className="pageTitle">Asset library</h2>
          <div className="Upload">
            <Tabs defaultActiveKey="PendingApproval">
              <Tab eventKey="PendingApproval" title="Pending Approval">
                <Row>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedPendingAssetType}
                      onChange={handlePendingAssetType}
                      options={pendingAssetTypeList}
                      placeholder="Asset Type"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedPendingBrand}
                      onChange={handlePendingBrand}
                      options={pendingBrandList}
                      placeholder="Brand"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedPendingFormat}
                      onChange={handlePendingFormat}
                      options={pendingFormatList}
                      placeholder="Format"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedPendingMarket}
                      onChange={handlePendingMarket}
                      options={pendingMarketList}
                      placeholder="Market"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedPendingConcept}
                      onChange={handlePendingConcept}
                      options={pendingConceptList}
                      placeholder="Concept"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                </Row>
                <div>
                  <Row>
                    {pendingBrandsToShow.length == 0 ? (
                      <Row className="mt-3">
                        <Col xs={12} lg={6} className="u-center">
                          <h2 className="Heading text-center">Empty</h2>
                          <p className="text-left">Looks like there are no pending approval assets.</p>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                    {pendingBrandsToShow &&
                      pendingBrandsToShow.map((campaign: any, index: any) => {
                        const selectedCount = selectedAssets[campaign.brand]?.size || 0;
                        const assetCount = campaign?.assets?.length;

                        return (
                          <>
                            <Row className="g-4 assetContainer" key={index}>
                              <Col xs={9} lg={9} key={index}>
                                <h6 className="mb-4">{campaign?.brand}</h6>
                                <Row className="g-4" key={index}>
                                  {campaign?.assets?.map((asset: any, assetIndex: any) => (
                                    <Col xs={12} lg={4} key={assetIndex}>
                                      <ProductCard
                                        className="ProductCard"
                                        campaign={asset}
                                        handleExpand={handleExpand}
                                        handleAccept={handleAccept}
                                        rejectReason={rejectReason}
                                        clearSelectedAssets={clearSelectedAssets}
                                        onSelect={() => handleAssetSelect(campaign.brand, asset.rowKey)}
                                        isSelected={selectedAssets[campaign.brand]?.has(asset.rowKey)}
                                        assetCount={assetCount}
                                        isExpand
                                      ></ProductCard>
                                    </Col>
                                  ))}
                                  {index === 1 && !pendingShowAll && finalPendingAssets.length > 2 && (
                                    <div className="text-end cursor-pointer" onClick={togglePendingShowAll}>
                                      <h6>
                                        Expand All <img src={DownIcon} alt="x" />
                                      </h6>
                                    </div>
                                  )}
                                  {index === finalPendingAssets?.length - 1 &&
                                    finalPendingAssets.length > 2 &&
                                    pendingShowAll && (
                                      <div className="text-end cursor-pointer" onClick={togglePendingShowAll}>
                                        <h6>
                                          Collapse All <img src={UpIcon} alt="x" />
                                        </h6>
                                      </div>
                                    )}
                                </Row>
                              </Col>
                              <Col xs={3} lg={3}>
                                {selectedCount > 1 && (
                                  <>
                                    <h6>{selectedCount} SELECTED</h6>
                                    <div className="acceptRejectIcon">
                                      <span className="tooltipWrapper">
                                        <img
                                          src={acceptIcon}
                                          onClick={() => confirmApprove(campaign.brand, 'Approved', selectedCount)}
                                          className="acceptIcon"
                                          alt="Accept"
                                          style={{ cursor: 'pointer' }}
                                          onMouseOver={(e) => (e.currentTarget.src = acceptIconHover)}
                                          onMouseOut={(e) => (e.currentTarget.src = acceptIcon)}
                                        />
                                        <span className="tooltip">Approve all selected assets</span>
                                      </span>
                                      {/* <span className="tooltipWrapper">
                                      <img
                                        src={rejectIcon}
                                        onClick={() => rejectReason(campaign.brand, 'Rejected')}
                                        className="rejectIcon"
                                        alt="Reject"
                                        style={{ cursor: 'pointer' }}
                                        onMouseOver={(e) => (e.currentTarget.src = rejectIconHover)}
                                        onMouseOut={(e) => (e.currentTarget.src = rejectIcon)}
                                      />
                                      <span className="tooltip">Reject All Selected Assets</span>
                                    </span> */}
                                    </div>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Approved" title="Approved">
                <Row>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedApprovedAssetType}
                      onChange={handleApprovedAssetType}
                      options={approvedAssetTypeList}
                      placeholder="Asset Type"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedApprovedBrand}
                      onChange={handleApprovedBrand}
                      options={approvedBrandList}
                      placeholder="Brand"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedApprovedFormat}
                      onChange={handleApprovedFormat}
                      options={approvedFormatList}
                      placeholder="Format"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedApprovedMarket}
                      onChange={handleApprovedMarket}
                      options={approvedMarketList}
                      placeholder="Market"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedApprovedConcept}
                      onChange={handleApprovedConcept}
                      options={approvedConceptList}
                      placeholder="Concept"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                </Row>
                <div>
                  <Row>
                    {approvedBrandsToShow.length == 0 ? (
                      <Row className="mt-3">
                        <Col xs={12} lg={6} className="u-center">
                          <h2 className="Heading text-center">Empty</h2>
                          <p className="text-left">
                            Looks like there are no approved assets. Assets will appear here after your approval.
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                    {approvedBrandsToShow &&
                      approvedBrandsToShow.map((campaign: any, index: any) => {
                        const selectedCount = selectedAssets[campaign.brand]?.size || 0;
                        return (
                          <>
                            <Row className="g-4 assetContainer" key={index}>
                              <Col xs={12} lg={12} key={index}>
                                <h6 className="mb-4">{campaign?.brand}</h6>
                                <Row className="g-4" key={index}>
                                  {campaign?.assets?.map((asset: any, assetIndex: any) => (
                                    <Col xs={12} lg={3} key={assetIndex}>
                                      <ProductCard
                                        className="ProductCard"
                                        campaign={asset}
                                        isExpand
                                        isDownload
                                      ></ProductCard>
                                    </Col>
                                  ))}
                                  {index === 1 && !approvedShowAll && finalApprovedAssets.length > 2 && (
                                    <div className="text-end cursor-pointer" onClick={toggleApprovedShowAll}>
                                      <h6>
                                        Expand All <img src={DownIcon} alt="x" />
                                      </h6>
                                    </div>
                                  )}
                                  {index === finalApprovedAssets?.length - 1 &&
                                    approvedShowAll &&
                                    finalApprovedAssets.length > 2 && (
                                      <div className="text-end cursor-pointer" onClick={toggleApprovedShowAll}>
                                        <h6>
                                          Collapse All <img src={UpIcon} alt="x" />
                                        </h6>
                                      </div>
                                    )}
                                </Row>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Rejected" title="Rejected">
                <Row>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedRejectedAssetType}
                      onChange={handleRejectedAssetType}
                      options={rejectedAssetTypeList}
                      placeholder="Asset Type"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedRejectedBrand}
                      onChange={handleRejectedBrand}
                      options={rejectedBrandList}
                      placeholder="Brand"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedRejectedFormat}
                      onChange={handleRejectedFormat}
                      options={rejectedFormatList}
                      placeholder="Format"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedRejectedMarket}
                      onChange={handleRejectedMarket}
                      options={rejectedMarketList}
                      placeholder="Market"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={2}>
                    <Select
                      isSearchable
                      isClearable
                      value={selectedRejectedConcept}
                      onChange={handleRejectedConcept}
                      options={rejectedConceptList}
                      placeholder="Concept"
                      styles={customStyles}
                      components={{
                        ClearIndicator: CustomClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  </Col>
                </Row>
                <div>
                  <Row>
                    {rejectedBrandsToShow.length == 0 ? (
                      <Row className="mt-3">
                        <Col xs={12} lg={6} className="u-center">
                          <h2 className="Heading text-center">Empty</h2>
                          <p className="text-left">
                            Looks like there are no rejected assets. Assets will appear here if you reject.
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}
                    {rejectedBrandsToShow &&
                      rejectedBrandsToShow.map((campaign: any, index: any) => {
                        const selectedCount = selectedAssets[campaign.brand]?.size || 0;
                        return (
                          <>
                            <Row className="g-4 assetContainer" key={index}>
                              <Col xs={12} lg={12} key={index}>
                                <h6 className="mb-4">{campaign?.brand}</h6>
                                <Row className="g-4" key={index}>
                                  {campaign?.assets?.map((asset: any, assetIndex: any) => (
                                    <Col xs={12} lg={3} key={assetIndex}>
                                      <ProductCard className="ProductCard" campaign={asset} isExpand></ProductCard>
                                    </Col>
                                  ))}
                                  {index === 1 && !rejectedShowAll && finalRejectedAssets.length > 2 && (
                                    <div className="text-end cursor-pointer" onClick={toggleRejectedShowAll}>
                                      <h6>
                                        Expand All <img src={DownIcon} alt="x" />
                                      </h6>
                                    </div>
                                  )}
                                  {index === finalRejectedAssets?.length - 1 &&
                                    finalRejectedAssets.length > 2 &&
                                    rejectedShowAll && (
                                      <div className="text-end cursor-pointer" onClick={toggleRejectedShowAll}>
                                        <h6>
                                          Collapse All <img src={UpIcon} alt="x" />
                                        </h6>
                                      </div>
                                    )}
                                </Row>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                  </Row>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="u-marginTlg"></div>

          <br />

          <PopUp
            className="hmPopup"
            key="approveAsset"
            show={confirmApprovePopUp}
            handleClose={handleConfirmApproveClose}
          >
            <p>Are you sure you want to approve {selectedCountValue} files? </p>
            <div className="text-center">
              <Button className="Button Button--outlined Button--iconFirst" onClick={handleConfirmApprove}>
                Yes
              </Button>
              <Button className="ms-3 Button Button--outlined Button--iconFirst" onClick={handleConfirmApproveClose}>
                Cancel
              </Button>
            </div>
          </PopUp>

          <PopUp
            className="hmPopup"
            key="assetApproved"
            show={approvalConfirmationPopUp}
            handleClose={handleApprovalConfirmationClose}
          >
            <Button variant="link" className="close-button" onClick={handleApprovalConfirmationClose}>
              <img src={removeIcon} alt="Close" />
            </Button>
            <div className="text-center">
              <img src={approveIcon} alt="Approve" />
            </div>
            <p className="mt-3">Done! The assets have been approved and you can view them under “Approved”.</p>
          </PopUp>

          <PopUp
            className="hmPopup"
            key="assetReject"
            show={rejectionConfirmationPopUp}
            handleClose={handleRejectionConfirmationClose}
          >
            <Button variant="link" className="close-button" onClick={handleRejectionConfirmationClose}>
              <img src={removeIcon} alt="Close" />
            </Button>
            <div className="text-center">
              <img src={approveIcon} alt="Approve" />
            </div>
            <p className="mt-3">Done! The assets have been rejected and you can view them under “Rejected”.</p>
          </PopUp>

          <PopUp
            show={rejectReasonPopUp}
            key="reasonReject"
            handleClose={handleRejectReasonClose}
            className={rejectReasonError ? 'rejectReasonPopUp error' : 'rejectReasonPopUp'}
          >
            <>
              <h6 className="mb-4">Reason for Rejection </h6>

              <Select
                options={predefinedReasonsList}
                value={selectedReason}
                onChange={handleSelectChange}
                placeholder="Select a reason"
                isSearchable
                isClearable
                styles={customStyles}
                components={{
                  ClearIndicator: CustomClearIndicator,
                  DropdownIndicator: CustomDropdownIndicator,
                }}
              />

              <textarea
                maxLength={rejectReasonTextLength}
                rows={5}
                cols={71}
                name="rejectReason"
                value={rejectReasonValue}
                placeholder={`Add maximum ${rejectReasonTextLength} characters`}
                // disabled={selectedReason?.value !== 'custom' && selectedReason !== null}
                onChange={handleComments}
              />
              {rejectReasonValue && (
                <div className="float-end">
                  {rejectReasonTextLength - rejectReasonValue.length}/{rejectReasonTextLength}
                </div>
              )}

              <div className={rejectReasonValue ? 'text-end pt-5' : 'text-end pt-4'}>
                <Button className="Button Button--outlined Button--iconFirst" onClick={handleRejectReasonClose}>
                  Cancel
                </Button>
                <Button className="ms-3 Button Button--iconFirst" disabled={isButtonDisabled} onClick={handleContinue}>
                  Continue
                </Button>
              </div>
            </>
          </PopUp>

          <PopUp
            show={rejectReasonSubmitPopUp}
            key="sendReason"
            handleClose={handleRejectReasonSubmitClose}
            className="rejectReasonPopUp"
          >
            <div>
              <h6>You will now send asset rejection to the partner with the following comments:</h6>

              {rejectReasonFinalValue && (
                <p className="commentsData">
                  <i>{rejectReasonFinalValue.trim()}</i>
                </p>
              )}
              <div className="text-end pt-4">
                <Button className="Button Button--outlined Button--iconFirst" onClick={handleRejectReasonSubmitClose}>
                  Cancel
                </Button>
                <Button className="ms-3 Button Button--iconFirst" onClick={handleRejectReason}>
                  Send Rejection
                </Button>
              </div>
            </div>
          </PopUp>

          {sentSuccess && (
            <div className="alert alert-success show-failed">
              <span>
                Great work! Your assets have been successfully sent for approval. <br></br> You can find them under
                “Pending Approval”.
              </span>
            </div>
          )}
          {showLoader && <Loader />}
          {showFailed && (
            <div className="alert alert-danger show-failed">
              <strong>Failed!</strong> An unexpected error occurred. Please try again later.
            </div>
          )}
        </Container>
      </div>
    </PermissionsGate>
  );
};

export default ApproveCampaign;
