import React, { useState, useMemo, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../components/Global/Grid/index.scss';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { getInvoiceDetail } from '../../../services/EInvoiceService';
import Loader from '../../../components/Shared/Loader';
import { exportToCSVInvoiceLineItems, formatNumber } from '../../../helpers/utilities';
import PermissionsGate from '../../../components/PermissionsGate';
import { SCOPES } from '../../../helpers/permission-maps';

const DisplayLineItems = () => {
  let history = useHistory();
  const [invoiceDetailData, setInvoiceDetailData] = useState<any>({});
  const { invoiceHeader, invoiceLineItems } = invoiceDetailData;
  const [showLoader, setShowLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [emptyMessage, setEmptyMessage] = useState<any>('No data found');
  const [total, setTotal] = useState('');
  let search = window.location.search;
  let par = new URLSearchParams(search);
  const invoiceId = par?.get('invoiceId');

  useEffect(() => {
    if (invoiceId) {
      setShowLoader(true);
      getInvoiceDetail(invoiceId)
        .then(function (response: any) {
          setShowLoader(false);
          const invoiceDetailData = response?.data;
          setInvoiceDetailData(invoiceDetailData);
        })
        .catch(function (error) {
          setShowLoader(false);
          console.log(error);
        });
    }
  }, [invoiceId]);

  const onInputchange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const onInputClear = () => {
    setSearchValue('');
    setTotal('');
  };

  const finalInvoiceLineItems = useMemo(() => {
    let computedInvoiceLineItems = invoiceLineItems;

    if (searchValue) {
      computedInvoiceLineItems =
        computedInvoiceLineItems &&
        computedInvoiceLineItems.filter(
          (invoice: any) =>
            invoice?.hybrisOrderNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
            invoice?.articleDescription.trim().toLowerCase().includes(searchValue.trim().toLowerCase()) ||
            invoice?.offerSku.trim().toLowerCase().includes(searchValue.trim().toLowerCase()),
        );
    }

    const totalValue = computedInvoiceLineItems?.reduce((acc, item) => acc + item.price.grossAmount, 0);
    if (totalValue && computedInvoiceLineItems?.length > 0) {
      setTotal(totalValue);
    } else {
      setTotal('');
    }

    return computedInvoiceLineItems;
  }, [invoiceLineItems, searchValue]);

  const exportToCSV = () => {
    exportToCSVInvoiceLineItems(finalInvoiceLineItems, invoiceId);
  };

  return (
    invoiceDetailData && (
      <PermissionsGate scopes={[SCOPES.canAccessDropShipInvoice]} redirect={true} canAccessDropShipInvoice={true}>
        <div className="displayLineItems">
          <Container fluid="xl">
            <Row>
              <Col xs={12} sm={12} md={12}>
                <ul className="Breadcrumb mt-4 mb-2">
                  <li className="Breadcrumb-item">
                    <div
                      onClick={() => {
                        history.push('/invoice-listing');
                      }}
                      className="Breadcrumb-link"
                    >
                      Invoicing
                    </div>
                  </li>
                  <li className="Breadcrumb-item">
                    <div
                      onClick={() => {
                        history.push('/invoice-listing/invoice?invoiceId=' + invoiceId);
                      }}
                      className="Breadcrumb-link"
                    >
                      Invoice
                    </div>
                  </li>
                  <li className="Breadcrumb-item">
                    <span className="Breadcrumb-current">Line Item Report</span>
                  </li>
                </ul>
                <h1 className="Heading Heading--h2 mb-2">Line Item Report</h1>
                <p className="u-textSizeXXXl">View the line items of your invoice below.</p>
                <div className="users-table">
                  <Row className="mb-5">
                    <Col xs={11} sm={11} md={11}>
                      <span>
                        <strong>Invoice ID:</strong> {invoiceHeader?.invoiceId}
                      </span>
                      <span className="ms-5">
                        <strong>Invoice Type :</strong> {invoiceHeader?.invoiceType}
                      </span>
                      <span className="ms-5">
                        <strong>Currency :</strong> {invoiceHeader?.invoiceCurrency}
                      </span>
                      {total && (
                        <span className="ms-5">
                          <strong>Total Amount {searchValue ? '(Filtered)' : ''}:</strong> {formatNumber(total)}
                        </span>
                      )}
                    </Col>
                    {finalInvoiceLineItems?.length > 0 && (
                      <Col xs={1} sm={1} md={1} className="text-end">
                        <div className="exportContainer">
                          <i className="icon-Download"></i>
                          <span className="export" onClick={exportToCSV}>
                            Export
                          </span>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={5} sm={5} md={5}>
                      <div className="right-inner-addon">
                        {searchValue && <i className="icon-Delete-circle" onClick={onInputClear}></i>}
                        <input
                          style={{ marginBottom: 20 }}
                          className={`form-control`}
                          value={searchValue}
                          placeholder="Search Article Name / Order ID / Offer SKU"
                          onChange={onInputchange}
                        />
                      </div>
                    </Col>
                  </Row>
                  {finalInvoiceLineItems?.length > 0 ? (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Article Name</th>
                          <th>Quantity</th>
                          <th>Offer SKU</th>
                          <th>Net Amount</th>
                          <th>VAT Amount</th>
                          <th>VAT Rate</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {finalInvoiceLineItems &&
                          finalInvoiceLineItems?.map((item: any) => {
                            return (
                              <tr key={item?.hybrisOrderNumberLineId}>
                                <td>{item?.hybrisOrderNumber}</td>
                                <td className="ellipsisText">{item?.articleDescription}</td>
                                <td>{item?.quantity}</td>
                                <td>{item?.offerSku}</td>
                                <td>{formatNumber(item?.price?.netAmount)}</td>
                                <td>{formatNumber(item?.price?.vatAmount)}</td>
                                <td>{formatNumber(item?.price?.vatRate)}%</td>
                                <td>{formatNumber(item?.price?.grossAmount)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="mt-3 text-center">{emptyMessage}</div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          {showLoader ? <Loader /> : ''}
        </div>
      </PermissionsGate>
    )
  );
};

export default DisplayLineItems;
