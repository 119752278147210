const getDetails = () => {
  let apiurl: string = '';
  let subscriptionKey: string = '';
  let showNaviagtionPartnerIds: any = [];
  let appUrl: string = window.location.origin;
  let devApiUrl = 'https://partnerportal-apim.azure-api.net/';
  let devSubscriptionKey = 'f127f9b668494586b9454f449a1fe8c4';
  let showNaviagtionEmails: any = [];

  //DEVELOPMENT
  if (appUrl == 'http://localhost:3000' || appUrl == 'https://partnerportaldevenv.azurewebsites.net') {
    apiurl = 'https://partnerportal-apim.azure-api.net/';
    subscriptionKey = 'f127f9b668494586b9454f449a1fe8c4';
    showNaviagtionPartnerIds = ['10069'];
    showNaviagtionEmails = [
      'thilak.chandar@hm.com',
      'sivaprakash.karthikeyan@hm.com',
      'valerie.downeybergstrom@hm.com',
      'sandesh.sawant@hm.com',
      'rebecca.nykvist@hm.com',
    ];
  }

  //STAGING
  else if (appUrl == 'https://nice-meadow-05d10ac03.2.azurestaticapps.net') {
    apiurl = 'https://partnerportal-apim-staging.azure-api.net/';
    subscriptionKey = '2c78bdd8be2e406782cfda609e3ceb81';
    showNaviagtionPartnerIds = ['10182'];
    showNaviagtionEmails = [
      'thilak.chandar@hm.com',
      'sivaprakash.karthikeyan@hm.com',
      'valerie.downeybergstrom@hm.com',
      'sandesh.sawant@hm.com',
      'rebecca.nykvist@hm.com',
    ];
  }

  //PRODUCTION
  else {
    apiurl = 'https://partnerportalprod-apim.azure-api.net/';
    subscriptionKey = '6eab5fcfa52a4c6baaff654a1d4b95e1';
    showNaviagtionPartnerIds = ['10182'];
    showNaviagtionEmails = [
      'thilak.chandar@hm.com',
      'sivaprakash.karthikeyan@hm.com',
      'valerie.downeybergstrom@hm.com',
      'sandesh.sawant@hm.com',
      'rebecca.nykvist@hm.com',
    ];
  }

  return { devApiUrl, devSubscriptionKey, apiurl, subscriptionKey, showNaviagtionPartnerIds, showNaviagtionEmails };
};
export default getDetails;
