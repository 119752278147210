import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import './index.scss';
import { updatePartnerEntity as updatePartnerEntityService } from '../../services/PartnersService';
import { getAllFilters } from '../../services/DataService';
import Loader from '../../components/Shared/Loader';
import { useDispatch } from 'react-redux';
import { updatePartnerEntity } from '../../store/actions/partnerEntityAction';

const UpdateEntity = (props: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [marketsList, setMarketsList] = useState<any[]>([]);
  const [businessModelList, setBusinessModelList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [integrationTypeList, setIntegrationTypeList] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    setValue('brandId', brandsList.filter((brand) => brand.brandName === props.selectedEntity.brand)?.[0]?.brandId);
    setValue(
      'marketId',
      marketsList.filter((market) => market.marketName === props.selectedEntity.market)?.[0]?.marketId,
    );
    setValue(
      'businessModelId',
      businessModelList.filter(
        (businessModel) => businessModel.businessModelName === props.selectedEntity.businessModel,
      )?.[0]?.businessModelId,
    );
    setValue(
      'categoryId',
      categoryList.filter((category) => category.categoryName === props.selectedEntity.category)?.[0]?.categoryId,
    );
    setValue(
      'integrationTypeId',
      integrationTypeList.filter(
        (integrationType) => integrationType.integrationTypeName === props.selectedEntity.integrationType,
      )?.[0]?.integrationTypeId,
    );
    setValue('shopId', props.selectedEntity.shopId);
    setValue('spurId', props.selectedEntity.supplierId);
    setValue('sapId', props.selectedEntity.sapId);
  }, [brandsList, businessModelList, marketsList, categoryList, integrationTypeList, setValue, props.selectedEntity]);

  const getFilters = () => {
    setShowLoader(true);
    getAllFilters()
      .then(function (data) {
        setMarketsList(data.markets);
        setBusinessModelList(data.businessModels);
        setCategoryList(data.categories);
        setBrandsList(data.brands);
        setIntegrationTypeList(data.integrationTypes);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const onSubmit = (data: any) => {
    const updateData = {};
    const selectedEntityData = props.selectedEntity;
    selectedEntityData.shopId = data.shopId;
    selectedEntityData.sapId = data.sapId;
    selectedEntityData.supplierId = data.spurId;
    setShowLoader(true);
    updateData['entityId'] = props.selectedEntity.entityId;
    updateData['shopId'] = data.shopId;
    updateData['sapId'] = data.sapId;
    updateData['spurId'] = data.spurId;

    updatePartnerEntityService(updateData)
      .then(function (data: any) {
        setShowLoader(false);
        dispatch(updatePartnerEntity(selectedEntityData));
        window.sessionStorage.setItem('selectedPartnerEntity', JSON.stringify(selectedEntityData));
        props.updateEntitySuccess();
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  const handleInputOnChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setValue(e.target.name, e.target.value);
    } else {
      setValue(e.target.name, e.target.value.replace(/\D/g, ''));
    }
  };

  return (
    <div className="popup-backdrop updateEntity">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            Update entity{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addUser-popup--body">
            <h5 className="text-center heading mb-4">{props.selectedPartner.partnerName}</h5>

            <select
              {...register('brandId', { required: true })}
              disabled
              className={`form-control ${errors.brandId ? 'error-control' : ''}`}
            >
              <option value="">Choose H&M Group brand</option>
              {brandsList &&
                brandsList.map((item: any) => {
                  return <option value={item.brandId}>{item.brandName}</option>;
                })}
            </select>
            {errors.brandId && <span>Please choose H&M Group brand</span>}

            <select
              {...register('marketId', { required: true })}
              disabled
              className={`form-control ${errors.marketId ? 'error-control' : ''}`}
            >
              <option value="">Choose market</option>
              {marketsList &&
                marketsList.map((item: any) => {
                  return <option value={item.marketId}>{item.marketName}</option>;
                })}
            </select>
            {errors.marketId && <span>Please choose market</span>}

            <select
              {...register('businessModelId', { required: true })}
              disabled
              className={`form-control ${errors.businessModelId ? 'error-control' : ''}`}
            >
              <option value="">Choose business model</option>
              {businessModelList &&
                businessModelList.map((item: any) => {
                  return <option value={item.businessModelId}>{item.businessModelName}</option>;
                })}
            </select>
            {errors.businessModelId && <span>Please choose business model</span>}

            <select
              {...register('categoryId', { required: true })}
              disabled
              className={`form-control ${errors.categoryId ? 'error-control' : ''}`}
            >
              <option value="">Choose category</option>
              {categoryList &&
                categoryList.map((item: any) => {
                  return <option value={item.categoryId}>{item.categoryName}</option>;
                })}
            </select>
            {errors.categoryId && <span>Please choose category</span>}

            <select
              {...register('integrationTypeId', { required: false })}
              disabled
              className={`form-control ${errors.integrationTypeId ? 'error-control' : ''}`}
            >
              <option value="">Choose integration type</option>
              {integrationTypeList &&
                integrationTypeList.map((item: any) => {
                  return <option value={item.integrationTypeId}>{item.integrationTypeName}</option>;
                })}
            </select>
            {errors.integrationTypeId && <span>Please choose integration type</span>}

            <label
              className="tool"
              data-tip="This field needs to be filled to enable the insights feature. Only needed for dropship partners."
            >
              <i className="icon-Info"></i>
            </label>
            <input
              {...register('shopId', {
                required: false,
                maxLength: 4,
                minLength: 4,
                onChange: (e) => handleInputOnChange(e),
              })}
              className={`form-control ${errors.shopId ? 'error-control' : ''}`}
              placeholder="Enter Mirakl Shop Id"
              autoComplete="off"
            />
            {errors.shopId && <span>Please enter your Mirakl Shop-id (Min and Max 4 digits)</span>}

            <label
              className="tool"
              data-tip="This field needs to be filled to enable the insights feature. Only needed for wholesale partners."
            >
              <i className="icon-Info"></i>
            </label>
            <input
              {...register('spurId', { required: false, onChange: (e) => handleInputOnChange(e) })}
              className={`form-control ${errors.spurId ? 'error-control' : ''}`}
              placeholder="Enter Spur Id"
              autoComplete="off"
            />
            {errors.spurId && <span>Please enter your Spur-id</span>}
          </div>

          <div className="addUser-popup--footer">
            <Button type="submit" className="Button">
              Save
            </Button>
          </div>
        </form>
        {showLoader ? <Loader /> : ''}
      </div>
    </div>
  );
};

export default UpdateEntity;
