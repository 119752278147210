import { useState, useEffect, useCallback } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import './index.scss';
import Button from '../../components/Shared/Button';
import DeleteEntity from '../DeleteEntity';
import Notification from '../Notification';

import { getPartnerDetail } from '../../services/PartnersService';
import { useDispatch } from 'react-redux';
import { updatePartnerEntity } from '../../store/actions/partnerEntityAction';
import Loader from '../../components/Shared/Loader';
import UpdateEntity from '../UpdateEntity';

const PartnerInformation = (props: any) => {
  const [partner, setPartner] = useState<any>({});
  const [partnerEntity, setPartnerEntity] = useState<any[]>([]);
  const [userType, setUserType] = useState('');
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const entityDetail = useSelector((state: RootStateOrAny) => state.selectedPartnerEntity);
  const [selectedEntity, setSelectedEntity] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteEntity, setDeleteEntity] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [showUpdateEntity, setUpdateEntity] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userTypeData.userType) {
      setUserType(userTypeData.userType);
      loadEntity(userTypeData.userType);
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      setUserType(usertype);
      loadEntity(usertype);
    }
  }, []);

  const loadEntity = (usertype: string) => {
    if (usertype == 'HmUser') {
      if (Object.keys(entityDetail.partnerEntity).length !== 0) {
        let entityData: any = [];
        entityData.push(entityDetail.partnerEntity);
        setPartnerEntity(entityData);
      } else {
        let entityItem: any = window.sessionStorage.getItem('selectedPartnerEntity');
        if (entityItem) {
          let selectedEntityData: any = JSON.parse(entityItem);
          let entityData: any = [];
          entityData.push(selectedEntityData);
          setPartnerEntity(entityData);
        }
      }

      if (Object.keys(partnerDetail.partner).length !== 0) {
        setPartner(partnerDetail.partner);
      } else {
        let partnerItem: any = window.sessionStorage.getItem('partnerDetail');
        let selectedPartnerData: any = JSON.parse(partnerItem);
        setPartner(selectedPartnerData);
      }
    } else {
      getPartner();
    }
  };

  const getPartner = () => {
    const partnerId: any = window.sessionStorage.getItem('partnerId');

    closePopup();
    setShowLoader(true);
    getPartnerDetail(partnerId)
      .then(function (data) {
        setPartner(data);
        let partnerItem: any = window.sessionStorage.getItem('partnerDetail');
        let selectedPartnerData: any = JSON.parse(partnerItem);
        if (selectedPartnerData?.partnerEntity) {
          setPartnerEntity(selectedPartnerData?.partnerEntity);
        } else {
          setPartnerEntity(data.partnerEntity);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const loadPartner = () => {
    closePopup();
    if (userType == 'HmUser') {
      if (updateClick) {
        loadEntity(userType);
      } else {
        setPartnerEntity([]);
        dispatch(updatePartnerEntity({}));
        window.sessionStorage.setItem('selectedPartnerEntity', '');
      }
    } else {
      getPartner();
    }
  };

  const deleteEntityData = {
    title: 'Delete entity',
    content: 'Entity has been deleted!',
    action: 'partnerInformation',
  };

  const updateEntityData = {
    title: 'Update entity',
    content: 'Entity has been updated!',
    action: 'partnerInformation',
  };

  const closePopup = () => {
    setUpdateEntity(false);
    setDeleteEntity(false);
    setNotification(false);
  };

  const updateEntity = (partner: any, entity: any) => {
    setPartner(partner);
    setSelectedEntity(entity);
    setUpdateEntity(true);
    setUpdateClick(true);
    setDeleteClick(false);
  };

  const deleteEntity = (entity: any) => {
    setSelectedEntity(entity);
    setDeleteEntity(true);
    setDeleteClick(true);
    setUpdateClick(false);
  };

  const deleteSuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(deleteEntityData);
  }, []);

  const updateEntitySuccess = useCallback((event) => {
    closePopup();
    setNotification(true);
    setNotificationData(updateEntityData);
  }, []);

  return (
    <div className="list partnerInformation" data-testid="partner-information-component">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          {/* <Accordion.Header>{partner.partnerName}</Accordion.Header> */}
          <Accordion.Body>
            <div className="users-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Brand</th>
                    <th>Business Unit</th>
                    <th>Category</th>
                    <th>integration type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {partnerEntity.filter((brand) => brand?.brandId === portfolioBrand?.value) &&
                    partnerEntity
                      .filter((brand) => brand?.brandId === portfolioBrand?.value)
                      .map((entity: any) => {
                        return (
                          <tr key={entity.entityID}>
                            <td>{entity.brand}</td>
                            <td>{entity.businessModel}</td>
                            <td>{entity.category}</td>
                            <td>{entity.integrationType}</td>
                            <td className="u-textRight">
                              {userType == 'HmUser' ? (
                                <Button
                                  className="Button Button--round Button--small Button--red Button--title"
                                  data-title="Delete entity"
                                  onClick={() => {
                                    deleteEntity(entity);
                                  }}
                                  data-testid="delete-entity-button"
                                >
                                  <i className="icon-Close"></i>
                                </Button>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {showDeleteEntity ? (
        <DeleteEntity
          entity={selectedEntity}
          partnerName={partner.partnerName}
          closePopup={closePopup}
          deleteSuccess={deleteSuccess}
        />
      ) : (
        ''
      )}
      {showUpdateEntity ? (
        <UpdateEntity
          selectedPartner={partner}
          selectedEntity={selectedEntity}
          closePopup={closePopup}
          updateEntitySuccess={updateEntitySuccess}
        />
      ) : (
        ''
      )}
      {showNotification ? (
        <Notification
          loadPartner={loadPartner}
          partnerName={partner.partnerName}
          data={notificationData}
          closePopup={closePopup}
        />
      ) : (
        ''
      )}
      {showLoader ? <Loader /> : ''}
    </div>
  );
};

export default PartnerInformation;
