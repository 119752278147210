const intialState = {
  allArticles: [],
};

const learnReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'learn/updateAllArticles':
      return {
        ...state,
        allArticles: action.payload,
      };
    case 'learn/getAllArticles':
      return state;
    default:
      return state;
  }
};

export default learnReducer;
