import { useState, useMemo, forwardRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../components/Global/Grid/index.scss';
import Button from '../../../components/Shared/Button';
import { useHistory } from 'react-router-dom';
import './index.scss';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import closeIcon from '../../../assets/images/close-icon.png';
import { getInvoiceList } from '../../../services/EInvoiceService';
import Loader from '../../../components/Shared/Loader';
import { getUniqueArray, formatNumber } from '../../../helpers/utilities';
import commentIcon from '../../../assets/images/icons/commentIcon.png';
import PermissionsGate from '../../../components/PermissionsGate';
import { SCOPES } from '../../../helpers/permission-maps';

const InvoiceListing = () => {
  let history = useHistory();
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<any[]>([]);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState<any[]>([]);
  const [fromDate, setFromDate] = useState(new Date()?.setMonth(new Date()?.getMonth() - 1));
  const [toDate, setToDate] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState<any>('No data found');
  const [statusList, setStatusList] = useState<any[]>([]);
  const [marketList, setMarketList] = useState<any[]>([]);
  const [invoiceTypeList, setInvoiceTypeList] = useState<any[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [showError, setShowError] = useState(false);
  const [notifyText, setNotifyText] = useState('');

  const selectedMarketLabel = JSON.parse(JSON.stringify(selectedMarket))?.label;
  const selectedStatusLabel = JSON.parse(JSON.stringify(selectedStatus))?.label;
  const selectedInvoiceTypeLabel = JSON.parse(JSON.stringify(selectedInvoiceType))?.label;

  const onInputchange = (event: any) => {
    setSearchValue(event.target.value);
    setIsSearch(true);
  };

  const onInputClear = () => {
    setSearchValue('');
    setIsSearch(true);
  };

  const viewInvoice = (invoiceId) => {
    history.push('/invoice-listing/invoice?invoiceId=' + invoiceId);
  };

  const handleStatus = (selectedOption: any) => {
    setSelectedStatus(selectedOption);
    setIsSearch(true);
  };

  const handleMarket = (selectedOption: any) => {
    setSelectedMarket(selectedOption);
    setIsSearch(true);
  };

  const handleInvoiceType = (selectedOption: any) => {
    setSelectedInvoiceType(selectedOption);
    setIsSearch(true);
  };

  useEffect(() => {
    let partnerDetail: any = sessionStorage.getItem('partnerDetail');
    let selectedPartnerData: any = JSON.parse(partnerDetail);
    const shopId = selectedPartnerData?.shopId;
    const startDate = moment(fromDate).format('YYYY-MM-DD');
    const endDate = moment(toDate).format('YYYY-MM-DD');

    const getData = () => {
      setShowLoader(true);
      getInvoiceList(shopId, startDate, endDate)
        .then(function (response: any) {
          const invoiceListData = response?.data?.invoices?.map((invoice) => {
            if (invoice.disputed && invoice.status === 'Accepted') {
              return { ...invoice, status: 'Accepted (Disputed)' };
            }
            return invoice;
          });

          setShowLoader(false);
          setInvoiceData(invoiceListData);
          const markets = getUniqueArray(invoiceListData, 'salesMarket');
          setMarketList(markets);
          const status = getUniqueArray(invoiceListData, 'status');
          setStatusList(status);
          const invoiceTypes = getUniqueArray(invoiceListData, 'invoiceType');
          setInvoiceTypeList(invoiceTypes);
          setIsSearch(false);
          if (response?.status !== 204 && response?.status !== 200) {
            if (response?.detail) {
              setInvoiceData(invoiceListData);
              setNotifyText(response?.detail);
              setShowError(true);
              setTimeout(() => {
                setShowError(false);
              }, 7000);
            }
          }
        })
        .catch(function (error) {
          setShowLoader(false);
          if (error.response) {
            setNotifyText(error?.response?.statusText);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 7000);
          }
        });
    };
    if (startDate && endDate) {
      getData();
    }
  }, [fromDate, toDate]);

  const finalInvoiceData = useMemo(() => {
    let computedInvoiceData = invoiceData;

    if (searchValue) {
      computedInvoiceData =
        computedInvoiceData &&
        computedInvoiceData.filter((invoice: any) =>
          invoice?.invoiceId.toLowerCase().includes(searchValue.toLowerCase()),
        );
    }

    if (selectedStatus && Object.keys(selectedStatus)?.length > 0) {
      const selectedStatusValue = JSON.parse(JSON.stringify(selectedStatus))?.label;
      computedInvoiceData =
        computedInvoiceData &&
        computedInvoiceData?.filter(
          (invoice: any) => invoice?.status?.toLowerCase() === selectedStatusValue.toLowerCase(),
        );
    }

    if (selectedMarket && Object.keys(selectedMarket)?.length > 0) {
      const selectedMarketValue = JSON.parse(JSON.stringify(selectedMarket))?.value;
      computedInvoiceData =
        computedInvoiceData &&
        computedInvoiceData?.filter((invoice: any) =>
          invoice?.salesMarket?.toLowerCase().includes(selectedMarketValue.toLowerCase()),
        );
    }

    if (selectedInvoiceType && Object.keys(selectedInvoiceType)?.length > 0) {
      const selectedInvoiceTypeValue = JSON.parse(JSON.stringify(selectedInvoiceType))?.value;
      computedInvoiceData =
        computedInvoiceData &&
        computedInvoiceData?.filter((invoice: any) =>
          invoice?.invoiceType?.toLowerCase().includes(selectedInvoiceTypeValue.toLowerCase()),
        );
    }

    // computedInvoiceData.sort((a, b) => {
    //   const dateA = new Date(a?.invoiceGenerationDate).getTime();
    //   const dateB = new Date(b?.invoiceGenerationDate).getTime();
    //   return dateB - dateA;
    // });

    // computedInvoiceData.sort((a, b) => {
    //   const numA = parseInt(a.invoiceId.split('-')[0], 10);
    //   const numB = parseInt(b.invoiceId.split('-')[0], 10);

    //   return numB - numA;
    // });

    computedInvoiceData?.sort((a, b) => b?.invoiceId?.localeCompare(a?.invoiceId));

    return computedInvoiceData;
  }, [invoiceData, searchValue, selectedStatus, selectedMarket, selectedInvoiceType]);

  const CustomDatepickerInput = forwardRef<any, any>(({ label, value, onClick }, ref) => (
    <button className="customDatepickerInput" onClick={onClick} ref={ref}>
      {label}: {moment(value).format('DD MMM YYYY')}
    </button>
  ));

  const handleDateChange = (date: any, target: any) => {
    setSearchValue('');
    setSelectedInvoiceType([]);
    setSelectedMarket([]);
    setSelectedStatus([]);
    if (target === 'from') {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  const handleRemoveMarket = () => {
    setSelectedMarket([]);
    setIsSearch(true);
  };

  const handleRemoveInvoiceType = () => {
    setSelectedInvoiceType([]);
    setIsSearch(true);
  };

  const handleRemoveStatus = () => {
    setSelectedStatus([]);
    setIsSearch(true);
  };

  const handleClearAll = () => {
    setSelectedStatus([]);
    setSelectedInvoiceType([]);
    setSelectedMarket([]);
    setIsSearch(true);
  };

  return (
    <PermissionsGate scopes={[SCOPES.canAccessDropShipInvoice]} redirect={true} canAccessDropShipInvoice={true}>
      <div className="invoiceList-page">
        <Container fluid="xl">
          <Row>
            <Col xs={12} sm={12} md={12}>
              <h1 className="mt-5 Heading Heading--h2">List of invoices</h1>
              <p className="u-textSizeXXXl">Find and filter your invoices below.</p>
              <div className="users-table">
                <Row>
                  {isSearch || finalInvoiceData?.length >= 1 ? (
                    <Col xs={2} sm={2} md={2}>
                      <div className="right-inner-addon">
                        {searchValue && <i className="icon-Delete-circle" onClick={onInputClear}></i>}
                        <input
                          style={{ marginBottom: 20 }}
                          className={`form-control`}
                          value={searchValue}
                          placeholder="Search"
                          onChange={onInputchange}
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col xs={2} sm={2} md={2}>
                    <DatePicker
                      selected={fromDate}
                      onChange={(date) => handleDateChange(date, 'from')}
                      maxDate={new Date()}
                      customInput={<CustomDatepickerInput label="From" />}
                      closeOnScroll={true}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                  <Col xs={2} sm={2} md={2}>
                    <DatePicker
                      selected={toDate}
                      onChange={(date) => handleDateChange(date, 'to')}
                      minDate={fromDate}
                      maxDate={new Date()}
                      customInput={<CustomDatepickerInput label="To" />}
                      closeOnScroll={true}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Col>
                  {isSearch || finalInvoiceData?.length >= 1 ? (
                    <Col xs={2} sm={2} md={2}>
                      <Select
                        isSearchable
                        value={null}
                        onChange={handleStatus}
                        options={statusList}
                        placeholder="Status"
                      />
                    </Col>
                  ) : null}
                  {isSearch || finalInvoiceData?.length >= 1 ? (
                    <Col xs={2} sm={2} md={2}>
                      <Select
                        isSearchable
                        value={null}
                        onChange={handleMarket}
                        options={marketList}
                        placeholder="Market"
                      />
                    </Col>
                  ) : null}
                  {isSearch || finalInvoiceData?.length >= 1 ? (
                    <Col xs={2} sm={2} md={2}>
                      <Select
                        isSearchable
                        value={null}
                        onChange={handleInvoiceType}
                        options={invoiceTypeList}
                        placeholder="Invoice"
                      />
                    </Col>
                  ) : null}
                </Row>
                {(selectedMarketLabel || selectedInvoiceTypeLabel || selectedStatusLabel) && (
                  <>
                    <p>
                      {((selectedStatusLabel && selectedMarketLabel) ||
                        (selectedInvoiceTypeLabel && selectedMarketLabel) ||
                        (selectedStatusLabel && selectedInvoiceTypeLabel)) && (
                        <span className="clearAll btn" onClick={handleClearAll}>
                          Clear All
                        </span>
                      )}
                      {selectedStatusLabel && (
                        <span className="filterLabel btn">
                          <i className="icon-Close" onClick={handleRemoveStatus}></i>Status:{' '}
                          <b>{selectedStatusLabel}</b>
                        </span>
                      )}
                      {selectedMarketLabel && (
                        <span className="filterLabel btn">
                          <i className="icon-Close" onClick={handleRemoveMarket}></i>Market:{' '}
                          <b>{selectedMarketLabel}</b>
                        </span>
                      )}
                      {selectedInvoiceTypeLabel && (
                        <span className="filterLabel btn">
                          <i className="icon-Close" onClick={handleRemoveInvoiceType}></i>Invoice Type:{' '}
                          <b>{selectedInvoiceTypeLabel}</b>
                        </span>
                      )}
                    </p>
                  </>
                )}

                {finalInvoiceData?.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Invoice ID</th>
                        <th>Net Amount</th>
                        <th>Gross Amount</th>
                        <th>Invoice Type</th>
                        <th>Date</th>
                        <th>Market</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {finalInvoiceData &&
                        finalInvoiceData?.map((item: any) => {
                          return (
                            <tr key={item?.invoiceId}>
                              <td>{item?.invoiceId}</td>
                              <td>
                                {item?.invoiceCurrency + ' ' + formatNumber(item?.invoiceAmount?.headerNetAmount)}
                              </td>
                              <td>
                                {item?.invoiceCurrency + ' ' + formatNumber(item?.invoiceAmount?.headerGrossAmount)}
                              </td>
                              <td>{item?.invoiceType}</td>
                              <td>{item?.invoiceGenerationDate}</td>
                              <td>{item?.salesMarket}</td>
                              <td>
                                {item?.status === 'Accepted (Disputed)' ? (
                                  <span>
                                    {item?.status}{' '}
                                    <span data-tooltip={item?.disputeReason} className="ms-1">
                                      <img src={commentIcon} alt="!" className="commentIcon" />
                                    </span>
                                  </span>
                                ) : (
                                  item?.status
                                )}
                              </td>
                              <td className="u-textRight">
                                <Button
                                  className="Button Button--round Button--small Button--transparentBlack mr-2 Button--title"
                                  data-title="View Invoice"
                                  onClick={() => {
                                    viewInvoice(item?.invoiceId);
                                  }}
                                >
                                  <i className="icon-edit-view"></i>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <div className="mt-3 text-center">{emptyMessage}</div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {showLoader ? <Loader /> : ''}
        {showError ? (
          <div className="alert alert-danger show-failed">
            <strong>Error!</strong> {notifyText}
          </div>
        ) : null}
      </div>
    </PermissionsGate>
  );
};

export default InvoiceListing;
