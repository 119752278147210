import React, { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bannerHome from '../../assets/images/homeNew.png';
import bannerBeauty from '../../assets/images/beauty.png';
import bannerFashion from '../../assets/images/fashion.png';
import Home_Teaser_howtoformatimages from '../../assets/images/Home_Teaser_howtoformatimages.jpg';
import Teaser from '../../components/Shared/Teaser';

import './index.scss';
const Dashboard = () => {
  const [username, setUsername] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [teaserArticles, setTeaserArticles] = useState<{ url: string; heading: string }[]>([]);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);

  useEffect(() => {
    let userInfo: any = window.sessionStorage.getItem('user');
    let userInfoDetail: any = JSON.parse(userInfo);

    if (user) {
      setUsername(userInfoDetail ? userInfoDetail.name : '');
    }

    if (userTypeData.userType) {
      if (userTypeData.userType != 'HmUser') {
        checkEntity();
      } else {
        setBannerImage(bannerHome);
        setCategoryName('Home');
      }
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      if (usertype != 'HmUser') {
        checkEntity();
      } else {
        setBannerImage(bannerHome);
        setCategoryName('Home');
      }
    }
  }, []);

  const checkEntity = () => {
    const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);
    let partnerInfo: any = window.sessionStorage.getItem('partnerDetail');
    let partnerInfoDetail: any = JSON.parse(partnerInfo);
    showBanner(
      partnerInfoDetail?.partnerEntity?.filter((brand) => brand?.brandId === portfolioBrand?.value)?.[0]?.category,
    );
  };

  const showBanner = (category: string) => {
    if (category == 'Home') {
      setBannerImage(bannerHome);
      setCategoryName('Home');
    } else if (category == 'Fashion') {
      setBannerImage(bannerFashion);
      setCategoryName('Fashion');
    } else if (category == 'Kids') {
      setBannerImage(Home_Teaser_howtoformatimages);
      setCategoryName('Kids');
    } else {
      setBannerImage(bannerBeauty);
      setCategoryName('Beauty');
    }
  };

  const dropshipHome = [
    {
      //Dropship home: product information home, important to know(ligger under partner responsibility), image requirments home
      url: '/article/learn/creating-products-dropship/product-information-homeware',
      heading: 'Product information',
    },
    {
      url: '/article/learn/partner-responsibility/important-to-know',
      heading: 'Important to know',
    },
    {
      url: '/article/learn/creating-products-dropship/image-requirements-home',
      heading: 'Image requirements',
    },
  ];
  const dropshipFashion = [
    //Dropship fashion: product information clothing(byt gärna etikett till "Product Information i front-end), important to know(ligger under partner responsibility), FAQ Dropship
    {
      url: '/article/learn/creating-products-dropship/product-information-clothing',
      heading: 'Product information',
    },
    {
      url: '/article/learn/partner-responsibility/important-to-know',
      heading: 'Important to know',
    },
    {
      url: '/article/learn/faq-dropship/customer-care',
      heading: 'FAQ',
    },
  ];
  const viadcFashion = [
    //via-dc fashion: product information clothing(byt gärna etikett till "Product Information i front-end), find order via dc fashion(byt gärna etikett till "Order Management"), FAQ Via-DC
    {
      url: '/article/learn/products-via-dc/product-information-clothing',
      heading: 'Product information',
    },
    {
      url: '/article/learn/order-management-via-dc/find-order-beauty2',
      heading: 'Order management',
    },
    {
      url: '/article/learn/faq-via-dc/onboarding',
      heading: 'FAQ',
    },
  ];
  const viadcBeauty = [
    //via-dc beauty: product information beauty, image requriements beauty, find order via-dc beauty(byt gärna etikett till "Order Management")
    {
      url: '/article/learn/products-via-dc/product-information-Beauty',
      heading: 'Product information',
    },
    {
      url: '/article/learn/products-via-dc/image-requirements-beauty',
      heading: 'Image requirements',
    },
    {
      url: '/article/learn/order-management-via-dc/find-order-beauty',
      heading: 'Order management',
    },
  ];

  // const showTeasers = (partnerUser: any) => {
  //   if (partnerUser.businessModel === 'Dropship') {
  //     if (partnerUser.category === 'Home') {
  //       setTeaserArticles(dropshipHome);
  //     } else if (partnerUser.category === 'Fashion') {
  //       setTeaserArticles(dropshipFashion);
  //     } else {
  //       setTeaserArticles([]);
  //     }
  //   } else {
  //     if (partnerUser.category === 'Fashion') {
  //       setTeaserArticles(viadcFashion);
  //     } else if (partnerUser.category === 'Beauty') {
  //       setTeaserArticles(viadcBeauty);
  //     } else {
  //       setTeaserArticles([]);
  //     }
  //   }
  // };

  return (
    <div className="dashboard-page">
      <div className="Dashbord-hero" style={{ background: '#63786A !important' }}>
        <Container>
          <Row>
            <Col xs={10}>
              <div className="Dashboard-header">
                <h1 className="Heading Dashboard-heading">DASHBOARD</h1>
                {/* <span className="Dashboard-subHeading">Select information and find your H&M contacts.</span> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={11}>
              <div className="Dashboard-user">
                <div className="Dashboard-userImage"></div>
                <h5 className="Heading Dashboard-welcomeUser pl-5">Welcome,</h5> <span>{username}.</span>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12} sm={8} md={8} lg={8}>
              <div className="Dashboard-banner">
                {bannerImage && <img src={bannerImage} alt="Banner" />}
                <h4 className="Dashboard-type">WELCOME</h4>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>

      <section className="Section">
        {teaserArticles.length > 0 && (
          <Container>
            <h3 className="Heading Dashbord-sectionHeading">Work smarter with H&M</h3>
            <Row className="gx-4 gy-4">
              <Col xs={12} sm={12} md={12} lg={6}>
                <Teaser className="Teaser" {...teaserArticles[0]} gradientClass="orchid"></Teaser>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3}>
                <Teaser className="Teaser Teaser--third" {...teaserArticles[1]} gradientClass="lightBlue"></Teaser>
              </Col>
              <Col xs={12} sm={13} md={6} lg={3}>
                <Teaser className="Teaser Teaser--third" {...teaserArticles[2]} gradientClass="foggyGray"></Teaser>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </div>
  );
};

export default Dashboard;
