const intialState = {
  filterData: {},
};

const campaignDownloadReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'campaign/updateDownloadCampaign':
      return {
        ...state,
        selectedCampaignDownload: action.payload,
      };
    case 'campaign/getDownloadCampaign':
      return state;
    default:
      return state;
  }
};

export default campaignDownloadReducer;
