import axios from 'axios';
import getDetails from '../api/EnviromentConfig';
let baseUrl: string = sessionStorage.getItem('activatedMode') ? getDetails().devApiUrl : getDetails().apiurl;
let subscriptionKey: string = sessionStorage.getItem('activatedMode')
  ? getDetails().devSubscriptionKey
  : getDetails().subscriptionKey;

export async function getInvoiceList(shopIds: string, startDate: string, endDate: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: baseUrl + 'EInvoice/GetInvoiceList?shopIds=' + shopIds + '&startDate=' + startDate + '&endDate=' + endDate,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response;
  } catch {
    return null;
  }
}

export async function getInvoiceDetail(invoiceId: string) {
  try {
    const response = await axios({
      method: 'GET',
      url: baseUrl + 'EInvoice/GetInvoiceDetail?invoiceId=' + invoiceId,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response;
  } catch {
    return null;
  }
}

export async function updateInvoice(invoiceData: any) {
  try {
    const response = await axios({
      method: 'POST',
      url: baseUrl + '/EInvoice/UpdateInvoice',
      data: invoiceData,
      headers: { 'Ocp-Apim-Subscription-Key': subscriptionKey },
    });
    return response.data;
  } catch {
    return null;
  }
}
