import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../components/Hero';
import InstructionList from '../../components/InstructionList';
import CtaWidget from '../../components/Shared/CtaWidget';
import Button from '../../components/Shared/Button';
import HeroImage from '../../assets/images/campainHero-image.svg';
import PermissionsGate from '../../components/PermissionsGate';
import { SCOPES } from '../../helpers/permission-maps';

const Campaigns = () => {
  let history = useHistory();

  const handleCampaign = () => {
    history.push('/assets/upload-asset');
  };

  return (
    <PermissionsGate scopes={[SCOPES.canCreateCampaigns]} redirect={true} canCreateCampaigns={true}>
      <div>
        <Hero className="Hero Hero-gradient" handleCampaign={handleCampaign} heroImage={HeroImage}>
          <h1 className="Heading Heading--h1 Hero-heading">Assets</h1>
          <p className="Hero-preamable">
            Upload your images and assets here and view which ones have been approved or rejected.
          </p>
          {/* <div className="Hero-cta Hero-indent">
          <Button className="Button Button--transparentBlack" data-testid="uploadNow" onClick={() => handleCampaign()}>
            Upload now
          </Button>
        </div> */}
        </Hero>

        <section className="Section">
          <InstructionList handleCampaign={handleCampaign}></InstructionList>
        </section>
        {/* <section className="Section">
        <Container fluid="xl">
          <Row className="justify-content-md-end">
            <Col xs={12} lg={10}>
              <CtaWidget className="CtaWidget CtaWidget--blue" handleCampaign={handleCampaign}></CtaWidget>
            </Col>
            <Col xs={1}></Col>
          </Row>
        </Container>
      </section> */}
      </div>
    </PermissionsGate>
  );
};

export default Campaigns;
