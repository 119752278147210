import './index.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
const TeaserContainerBlock = (props: any) => {
  return (
    <>
      <div className="Article-content Article-content--size1of3" data-testid="TeaserContainerBlock">
        <h2 className="Heading Article-sectionHeading">{props.block.data?.headline}</h2>
        <Row>
          {props.block.data?.items.map((item: any) => {
            return (
              <Col lg={4}>
                <div className="Article-image">
                  <img src={item.image} />
                </div>
                <h6 className="Heading">{item.heading}</h6>
                <p className="ArticleText">{item.preamble?.replace(/(\/(learn|sustainability)\/)/g, '/article$1')}</p>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};
export default TeaserContainerBlock;
