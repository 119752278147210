import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import './index.scss';
import { createPartnerEntity } from '../../services/PartnersService';
import { getAllFilters } from '../../services/DataService';
import Loader from '../../components/Shared/Loader';

const AddEntity = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [businessModelList, setBusinessModelList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [brandsList, setBrandsList] = useState<any[]>([]);
  const [integrationTypeList, setIntegrationTypeList] = useState<any[]>([]);
  const [showFailed, setShowFailed] = useState(false);
  const [notifyText, setNotifyText] = useState('');

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = () => {
    setShowLoader(true);
    getAllFilters()
      .then(function (data) {
        setBusinessModelList(data.businessModels);
        setCategoryList(data.categories);
        setBrandsList(data.brands?.sort((a, b) => a?.brandName?.localeCompare(b?.brandName, 'sv')));
        setIntegrationTypeList(data.integrationTypes);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const onSubmit = (data: any) => {
    setShowLoader(true);
    data['partnerId'] = props.selectedPartner.partnerId;

    if (data['integrationTypeId']) {
      data['integrationTypeId'] = data['integrationTypeId'];
    } else {
      data['integrationTypeId'] = '10005';
    }

    createPartnerEntity(data)
      .then(function (data: any) {
        setShowLoader(false);
        if (data === 'Entity already exist') {
          setNotifyText('Entity already exist.');
          setShowFailed(true);
          setTimeout(() => {
            setShowFailed(false);
          }, 5000);
        } else {
          props.addEntitySuccess();
        }
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="popup-backdrop addEntity">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            Add new entity{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
              data-testid="closePopup"
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} data-testid="addEntity-form">
          <div className="addUser-popup--body">
            <h5 className="text-center heading mb-4">{props.selectedPartner.partnerName}</h5>

            <select
              {...register('brandId', { required: true })}
              className={`form-control ${errors.brandId ? 'error-control' : ''}`}
              data-testid="chooseBrand"
            >
              <option value="">H&M Group brand</option>
              {brandsList &&
                brandsList.map((item: any) => {
                  return (
                    <option value={item.brandId} key={item.brandId}>
                      {item.brandName}
                    </option>
                  );
                })}
            </select>
            {errors.brandId && <span>Please choose H&M Group brand</span>}

            <select
              {...register('businessModelId', { required: true })}
              className={`form-control ${errors.businessModelId ? 'error-control' : ''}`}
              data-testid="chooseBusinessModel"
            >
              <option value="">Business model</option>
              {businessModelList &&
                businessModelList.map((item: any) => {
                  return (
                    <option value={item.businessModelId} key={item.businessModelId}>
                      {item.businessModelName}
                    </option>
                  );
                })}
            </select>
            {errors.businessModelId && <span>Please choose business model</span>}

            <select
              {...register('categoryId', { required: true })}
              className={`form-control ${errors.categoryId ? 'error-control' : ''}`}
              data-testid="chooseCategory"
            >
              <option value="">Category</option>
              {categoryList &&
                categoryList.map((item: any) => {
                  return (
                    <option value={item.categoryId} key={item.categoryId}>
                      {item.categoryName}
                    </option>
                  );
                })}
            </select>
            {errors.categoryId && <span>Please choose category</span>}

            <select
              {...register('integrationTypeId', { required: false })}
              className={`form-control ${errors.integrationTypeId ? 'error-control' : ''}`}
              data-testid="chooseIntegrationType"
            >
              <option value="">Integration type</option>
              {integrationTypeList &&
                integrationTypeList.map((item: any) => {
                  return (
                    <option value={item.integrationTypeId} key={item.integrationTypeId}>
                      {item.integrationTypeName}
                    </option>
                  );
                })}
            </select>
            {errors.integrationTypeId && <span>Please choose integration type</span>}
          </div>

          <div className="addUser-popup--footer">
            <Button type="submit" data-testid="save" className="Button">
              Save
            </Button>
          </div>
        </form>
        {showLoader ? <Loader /> : ''}
        {showFailed ? (
          <div className="alert alert-danger show-failed">
            <strong>Failed!</strong> {notifyText}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddEntity;
