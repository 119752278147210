export default function useGetRoles() {
  let partnerData = JSON.parse(window.sessionStorage.getItem('partnerDetail')!);
  const portfolioBrand = JSON.parse(window.sessionStorage.getItem('portfolioBrand')!);
  let userRoles: string[] = [];

  if (window.sessionStorage.getItem('userType') === 'Partner') {
    userRoles.push('PARTNER');

    // if(sessionStorage.getItem("activatedMode")) {
    //   userRole = 'PARTNER_PRESENTATION_MODE'
    // }
    // console.log("permissions partnerData", partnerData)
    partnerData?.partnerEntity
      ?.filter((brand) => brand?.brandId === portfolioBrand?.value)
      ?.map((item: any) => {
        //console.log("permission", item)

        if (item?.businessModelId && item?.brandId === '1') {
          //PARTNER WHOLESALE FASHION
          if (item?.businessModelId === '10001' && item?.categoryId === '10001') {
            userRoles.push('PARTNER_VIA_DC_FASHION');
          }

          //PARTNER WHOLESALE HOME
          if (item?.businessModelId === '10001' && item?.categoryId === '10002') {
            userRoles.push('PARTNER_VIA_DC');
          }

          //PARTNER WHOLESALE BEAUTY
          if (item?.businessModelId === '10001' && item?.categoryId === '10003') {
            userRoles.push('PARTNER_VIA_DC_BEAUTY');
          }

          //DROPSHIP - FASHION
          if (item?.businessModelId === '10002' && item?.categoryId === '10001') {
            userRoles.push('PARTNER_DROPSHIP_FASHION');
          }

          //DROPSHIP - BEAUTY
          if (item?.businessModelId === '10002' && item?.categoryId === '10003') {
            userRoles.push('PARTNER_DROPSHIP_BEAUTY');
          }

          //DROPSHIP - HOME
          if (item?.businessModelId === '10002' && item?.categoryId === '10002') {
            userRoles.push('PARTNER_DROPSHIP_HOME');
          }

          //DROPSHIP - KIDS
          if (item?.businessModelId === '10002' && item?.categoryId === '10004') {
            userRoles.push('PARTNER_DROPSHIP_KIDS');
          }

          //WHOLESALE - Kids
          if (item?.businessModelId === '10001' && item?.categoryId === '10004') {
            userRoles.push('PARTNER_VIA_DC_KIDS');
          }
        }
      });

    // if(JSON.parse(window.sessionStorage.getItem("partnerDetail")!)?.sapId){
    //   userRole = 'PARTNER_SAP'
    // }

    // if(JSON.parse(window.sessionStorage.getItem("partnerDetail")!)?.supplierId){
    //   userRole = 'PARTNER_SUPPLIERID'
    // }
  } else {
    userRoles.push('HMUSER');
    if (sessionStorage.getItem('activatedMode')) {
      userRoles.push('HMUSER_PRESENTATION_MODE');
    }
  }

  userRoles = Array.from(new Set(userRoles));

  return userRoles;
}
