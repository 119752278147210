import PartnerUser from '../../types/PartnerUser';

const intialState = {
  currentPartnerUser: {},
};

const currentPartnerUserReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'currentPartnerUser/updateCurrentPartnerUser':
      return {
        ...state,
        currentPartnerUser: action.payload,
      };
    case 'currentPartnerUser/getCurrentPartnerUser':
      return state;
    default:
      return state;
  }
};

export default currentPartnerUserReducer;
