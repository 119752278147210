const intialState = {
  userType: '',
};

const userTypeReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'userType/updateUserType':
      return {
        ...state,
        userType: action.payload,
      };
    case 'userType/getUserType':
      return state;
    default:
      return state;
  }
};

export default userTypeReducer;
