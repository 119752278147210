import { useEffect, useState } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import closeIcon from '../../assets/images/close-icon.png';
import { useForm } from 'react-hook-form';
import { createHmUser, getHmUser } from '../../services/HmUsersService';
import Loader from '../../components/Shared/Loader';
import './index.scss';

const AddUser = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [notifyText, setNotifyText] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const userDetail = useSelector((state: RootStateOrAny) => state.user);

  useEffect(() => {
    let userEmail: any = '';
    if (userDetail.user?.username) {
      userEmail = userDetail.user?.username;
    } else {
      userEmail = window.sessionStorage.getItem('userEmail');
    }
    getHmUserDetail(userEmail);
  }, []);

  const getHmUserDetail = (email: string) => {
    setShowLoader(true);
    getHmUser(email)
      .then(function (resp) {
        if (resp) setIsAdmin(resp.data[0].isEditor);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const handleOptimizelyAccess = (event: any) => {
    setIsEditor(event.target.checked);
  };

  const onSubmit = (data: any) => {
    data['isEditor'] = isEditor;
    data['firstName'] = data.firstName?.trim();
    data['lastName'] = data.lastName?.trim();
    data['email'] = data.email?.trim();
    setShowLoader(true);
    createHmUser(data)
      .then(function (data) {
        setShowLoader(false);
        if (data == 'User already exist') {
          setNotifyText('User already exist.');
          setShowFailed(true);
          setTimeout(() => {
            setShowFailed(false);
          }, 5000);
        } else {
          props.addSuccess(data);
        }
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  return (
    <div className="popup-backdrop" data-testid="add-user-component">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6>
            Add new user{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
            >
              <img src={closeIcon} alt="x" />
            </span>
          </h6>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="addUser-popup--body">
            <input
              {...register('firstName', { required: true })}
              className={`form-control ${errors.firstName ? 'error-control' : ''}`}
              placeholder="First name"
              autoComplete="off"
            />
            {errors.firstName && <span>Please enter your firstname</span>}

            <input
              {...register('lastName', { required: true })}
              className={`form-control ${errors.lastName ? 'error-control' : ''}`}
              placeholder="Last name"
              autoComplete="off"
            />
            {errors.lastName && <span>Please enter your lastname</span>}

            <input
              {...register('email', {
                required: 'required',
                pattern: { value: /\S*@hm.com$/, message: 'Entered value does not match email format' },
              })}
              className={`form-control ${errors.email ? 'error-control' : ''}`}
              placeholder="Email address"
              autoComplete="off"
            />
            {errors.email && <span>Please enter your H&M Group email</span>}

            <div className={`${isAdmin ? '' : 'hide-access'}`}>
              <label className="optimizely-access">
                <input type="checkbox" onChange={handleOptimizelyAccess} /> Give optimizely access{' '}
              </label>
              <label
                className="tool"
                data-tip="Optimizely is the content management system of the Partner Hub. Giving this access means that the user will have control to edit content on the Partner Hub. Access can only be given if you have the access yourself."
              >
                <i className="icon-Info"></i>
              </label>
            </div>
          </div>

          <div className="addUser-popup--footer">
            <Button className="Button Button--transparentBlack" type="submit" data-testid="addUser">
              Add user
            </Button>
          </div>
        </form>
      </div>
      {showLoader ? <Loader /> : ''}
      {showFailed ? (
        <div className="alert alert-danger show-failed">
          <strong>Failed!</strong> {notifyText}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddUser;
