const intialState = {
  filterData: {},
};

const partnerFilterReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'partnerFilter/updatePartnerFilter':
      return {
        ...state,
        partnerFilter: action.payload,
      };
    case 'partnerFilter/getPartnerFilter':
      return state;
    default:
      return state;
  }
};

export default partnerFilterReducer;
