import { NavLink, useLocation } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import AzureAuthenticationContext from '../../azure/azure-authentication-context';
import './index.scss';
import logoLarge from '../../assets/images/logo.png';
import logoSmall from '../../assets/images/logo.png';
import ArketLogo from '../../assets/images/ArketLogo.png';
import hmLogo from '../../assets/images/HM-logo.svg';
import ProfileWidget from '../ProfileWidget';
import axios from 'axios';
import rulerIcon from '../../assets/images/icons/Ruler.svg';
import PermissionsGate from '../PermissionsGate';
import { SCOPES } from '../../helpers/permission-maps';
import { useEffect, useState } from 'react';
import getDetails from '../../api/EnviromentConfig';
import { updateAllArticles } from '../../store/actions/learnAction';
import otherStorieslogo from '../../assets/images/&_Other_Stories_logo.png';
import monkiLogo from '../../assets/images/monkiLogo.png';
import weekdayLogo from '../../assets/images/weekdayLogo.png';

const Navigation = (props: any) => {
  const dispatch = useDispatch();
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [allArticles, setAllArticles] = useState<any>([]);
  const [currentArticleUrl, setCurrentArticleUrl] = useState('');
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
  const user = useSelector((state: RootStateOrAny) => state.user);
  const showNaviagtionPartnerIds = getDetails().showNaviagtionPartnerIds;
  const partnerId: any = window.sessionStorage.getItem('partnerId');
  const learn = useSelector((state: RootStateOrAny) => state.learn);
  const userEmail = window.sessionStorage.getItem('userEmail');
  const location = useLocation();
  const pageType = window.sessionStorage.getItem('pageType');
  const [portfolioBrand, setPortfolioBrand] = useState(JSON.parse(window.sessionStorage.getItem('portfolioBrand')!));
  const [logo, setLogo] = useState(logoLarge);
  const showNaviagtionEmails = getDetails().showNaviagtionEmails;

  useEffect(() => {
    const currentPathName = location.pathname?.split('/')?.[1];
    if (currentPathName === 'learn' || currentPathName === 'article') {
      setCurrentArticleUrl(location.pathname);
    } else {
      dispatch(updateAllArticles({ allArticles: [] }));
      window.sessionStorage.setItem('allArticles', JSON.stringify([]));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (portfolioBrand?.value === '1') {
      setLogo(hmLogo);
    } else if (portfolioBrand?.value === '2') {
      setLogo(ArketLogo);
    } else if (portfolioBrand?.value === '3') {
      setLogo(otherStorieslogo);
    } else if (portfolioBrand?.value === '4') {
      setLogo(monkiLogo);
    } else if (portfolioBrand?.value === '5') {
      setLogo(weekdayLogo);
    } else {
      setLogo(logoLarge);
    }
  }, [portfolioBrand]);

  useEffect(() => {
    const sessionAllArticles: any = window?.sessionStorage?.getItem('allArticles')
      ? window?.sessionStorage?.getItem('allArticles')
      : [];
    if (learn?.allArticles.length > 0) {
      setAllArticles(learn?.allArticles);
    } else {
      if (sessionAllArticles?.length > 0) {
        setAllArticles(JSON.parse(sessionAllArticles));
      } else {
        setAllArticles([]);
      }
    }
  }, [learn]);

  const logOut = (): any => {
    if (user) {
      // CMS Logout
      // Below should not use REACT_APP_CMS_SEARCH_API but an environment variable that contains just the base url (which SEARCH does at the moment)
      const res = axios({
        withCredentials: true,
        method: 'POST',
        url: process.env.REACT_APP_CMS_SEARCH_API + '/partnerportal/logout',
      })
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });

      // Azure Logout
      authenticationModule.logout(user);
      sessionStorage.removeItem('idToken');
      sessionStorage.clear();
    }
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    props.setNavCollapsed();
  };

  //get categoryid and businessmodelid from session
  const entityValues = window.sessionStorage.getItem('entitySelectedOption')?.split(':');
  const categoryId = entityValues ? entityValues[0] : '';
  const businessModelId = entityValues ? entityValues[1] : '';

  return (
    <nav
      className={`Navigation printHidden Navigation-position ${isNavCollapsed ? '' : 'Navigation--extended'}`}
      data-testid="navigation-component"
    >
      <div className="Navigation-SlideTrigger" onClick={() => handleNavCollapse()} data-testid="slide-trigger"></div>

      <div>
        <div className="Navigation-logoWrapper">
          <img
            src={logo}
            alt="Logo"
            className={`Navigation-logo Navigation-logo--Large ${
              portfolioBrand?.value === '1' ? '' : 'Navigation-logo-customWidth'
            }`}
          />
          <img
            src={logo}
            alt="Logo"
            className={`Navigation-logo Navigation-logo--small ${
              portfolioBrand?.value === '1' ? '' : 'Navigation-logo-customWidth'
            }`}
          />
        </div>
        <ul className="Navigation-list">
          <PermissionsGate scopes={[SCOPES.canAccessDashboard]}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Dashboard"
                to="/dashboard"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-dashboard"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.canApproveCampaigns]} canApproveCampaigns={true}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Assets"
                to="/approve-asset"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-Upload"></i>
                <span>Assets</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.canCreateCampaigns]} canCreateCampaigns={true}>
            <li className="Navigation-listItem">
              <NavLink className="Navigation-listLink" data-title="Assets" to="/assets" activeClassName="is-active">
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-Upload"></i>
                <span>Assets</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.canAccessPartnerProfile]}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Partner Profiles"
                to="/partner-profile"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-User"></i>
                <span>Partner Profiles</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.canAccessPartnerAccount]}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Partner Profiles"
                to="/partner-account"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-User"></i>
                <span>Partner Profile</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.canAccessHmUsers]}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="H&M Group Users"
                to="/hm-user"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-User-multiple"></i>
                <span>H&M Group Users</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <li className="Navigation-listItem">
            <NavLink className="Navigation-listLink" data-title="Learn" to="/learn" activeClassName="is-active">
              <i className="Navigation-notifyer"></i>
              <span className="Navigation-active"></span>
              <i className="icon-Learn"></i>
              <span>Learn</span>
            </NavLink>
          </li>

          {allArticles &&
            allArticles?.map((category, index) => {
              const selectedArticleUrl = currentArticleUrl.replace('/article', '');
              return (
                !isNavCollapsed && (
                  <li
                    className={
                      category?.urlList.includes(selectedArticleUrl)
                        ? 'Navigation-listItem hasSubmenu is-active'
                        : 'Navigation-listItem hasSubmenu'
                    }
                  >
                    <NavLink
                      className={
                        category?.urlList.includes(selectedArticleUrl)
                          ? 'Navigation-listLink hasSubmenu is-active'
                          : 'Navigation-listLink hasSubmenu'
                      }
                      data-title={category?.catHeading}
                      to={{
                        pathname: '/article' + category?.url,
                        search:
                          '?categoryId=' +
                          categoryId +
                          '&businessModelId=' +
                          businessModelId +
                          '&pageType=' +
                          pageType +
                          '&portfolioBrand=' +
                          portfolioBrand?.value,
                        state: { cat: category },
                      }}
                      activeClassName="is-active"
                    >
                      <i className="Navigation-notifyer"></i>
                      {/* <span className="Navigation-active"></span> */}
                      <span>{category?.catHeading}</span>
                    </NavLink>
                  </li>
                )
              );
            })}

          <li className="Navigation-listItem">
            <NavLink
              className="Navigation-listLink"
              data-title="Sustainability"
              to="/sustainability"
              activeClassName="is-active"
            >
              <i className="Navigation-notifyer"></i>
              <span className="Navigation-active"></span>
              <i className="icon-Learn"></i>
              <span>Sustainability</span>
            </NavLink>
          </li>

          <PermissionsGate scopes={[SCOPES.canAccessPresentationMode]}>
            {!window.sessionStorage.getItem('activatedMode') && (
              <li className="Navigation-listItem">
                <NavLink
                  className="Navigation-listLink"
                  data-title="Presentation mode"
                  to="/presentation-mode"
                  activeClassName="is-active"
                >
                  <i className="Navigation-notifyer"></i>
                  <span className="Navigation-active"></span>
                  <i className="icon-Info"></i>
                  <span>Presentation mode</span>
                </NavLink>
              </li>
            )}
          </PermissionsGate>

          {window.sessionStorage.getItem('activatedMode') && (
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Presentation mode"
                to="/presentation-mode"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-Info"></i>
                <span>Presentation mode</span>
              </NavLink>
            </li>
          )}

          <PermissionsGate scopes={[SCOPES.canAccessInsights]}>
            <li className="Navigation-listItem">
              <NavLink className="Navigation-listLink" data-title="Insights" to="/insights" activeClassName="is-active">
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-Money-Graph"></i>
                <span>Insights</span>
              </NavLink>
            </li>
          </PermissionsGate>

          {!window.sessionStorage.getItem('activatedMode') &&
            showNaviagtionPartnerIds.includes(partnerId) &&
            portfolioBrand?.value === '1' && (
              <PermissionsGate scopes={[SCOPES.canAccessSizes]} canAccessSizes={true}>
                <li className="Navigation-listItem">
                  <NavLink className="Navigation-listLink" data-title="Sizes" to="/sizes" activeClassName="is-active">
                    <i className="Navigation-notifyer"></i>
                    <span className="Navigation-active"></span>
                    <img src={rulerIcon} />
                    <span>Sizes</span>
                  </NavLink>
                </li>
              </PermissionsGate>
            )}

          <PermissionsGate scopes={[SCOPES.canAccessDropShipInvoice]} canAccessDropShipInvoice={true}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Invoicing"
                to="/invoice-listing"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-File-text"></i>
                <span>Invoicing</span>
              </NavLink>
            </li>
          </PermissionsGate>

          {/* <PermissionsGate scopes={[SCOPES.canAccessSupport]} canAccessSupport={true}>
                        <li className='Navigation-listItem'>
                            <NavLink className='Navigation-listLink' data-title="Support" to="/support" activeClassName='is-active'>
                                <i className='Navigation-notifyer'></i>
                                <span className='Navigation-active'></span>
                                <i className="icon-Graph"></i><span>Support</span>
                            </NavLink>
                        </li>
                    </PermissionsGate> */}

          <PermissionsGate scopes={[SCOPES.canAccessContacts]}>
            <li className="Navigation-listItem">
              <NavLink className="Navigation-listLink" data-title="Contact" to="/contact" activeClassName="is-active">
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-Envelope"></i>
                <span>Contact</span>
              </NavLink>
            </li>
          </PermissionsGate>

          <PermissionsGate scopes={[SCOPES.canAccessCompanyInfo]}>
            <li className="Navigation-listItem">
              <NavLink
                className="Navigation-listLink"
                data-title="Company"
                to="/company-information"
                activeClassName="is-active"
              >
                <i className="Navigation-notifyer"></i>
                <span className="Navigation-active"></span>
                <i className="icon-Info"></i>
                <span>Company Information</span>
              </NavLink>
            </li>
          </PermissionsGate>
        </ul>
      </div>

      <div className="Navigation-profile">
        <ProfileWidget logOut={logOut} portfolioBrand={setPortfolioBrand}></ProfileWidget>
      </div>
    </nav>
  );
};

export default Navigation;
