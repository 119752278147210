import { useHistory } from 'react-router-dom';
import './index.scss';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const Breadcrumb = (props: any) => {
  let history = useHistory();
  const [userType, setUserType] = useState('');
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let hideLeftNav = params.has('from') ? true : false;

  useEffect(() => {
    if (userTypeData.userType) {
      setUserType(userTypeData.userType);
    } else {
      let usertype: any = window.sessionStorage.getItem('userType');
      setUserType(usertype);
    }
  }, []);

  const getCrumbs = () => {
    if (props.onBoarding) {
      return (
        <ul className="Breadcrumb">
          <li className="Breadcrumb-item">
            <span className="Breadcrumb-current">
              <a
                onClick={() => {
                  history.push(props.rootName ? props.rootUrl : '/assets');
                }}
                className="Breadcrumb-link"
              >
                {' '}
                {props.pageOn ? (
                  props.pageOn
                ) : userType != 'HmUser' ? (
                  <span>upload files</span>
                ) : (
                  <span>uploaded files</span>
                )}{' '}
              </a>
            </span>
          </li>
          <li className="Breadcrumb-item">{props.rootName && props.rootName}</li>
        </ul>
      );
    } else {
      return (
        <ul className="Breadcrumb">
          {hideLeftNav && userType != 'HmUser' ? (
            <li className="Breadcrumb-item">
              <a
                onClick={() => {
                  history.push(props.rootName ? props.rootUrl : '/assets');
                }}
                className="Breadcrumb-link"
              >
                {props.rootName ? props.rootName : 'campaigns'}
              </a>
            </li>
          ) : (
            ''
          )}

          <li className="Breadcrumb-item">
            <span className="Breadcrumb-current">
              <a
                onClick={() => {
                  history.push(props.rootName ? props.rootUrl : '/assets');
                }}
                className="Breadcrumb-link"
              >
                {' '}
                {props.pageOn ? (
                  props.pageOn
                ) : userType != 'HmUser' ? (
                  <span>upload files</span>
                ) : (
                  <span>uploaded files</span>
                )}{' '}
              </a>
            </span>
          </li>
          <li className="Breadcrumb-item">{props.rootName && props.rootName}</li>
        </ul>
      );
    }
  };

  return userType !== 'HmUser' ? getCrumbs() : null;
};

export default Breadcrumb;
