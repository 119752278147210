import Button from '../../components/Shared/Button';
import '../Global/Popup/popup.scss';
import successIcon from '../../assets/images/success-icon.png';
import './index.scss';

const Notification = (props: any) => {
  return (
    <div className="popup-backdrop notificationSideBar">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            {props.data.title}{' '}
            <span
              onClick={() => {
                props.closePopup(props.data.action);
              }}
              data-testid="notificationClosePopup"
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <div className="addUser-popup--body">
          <div className="addUser-popup--body--notification">
            <img src={successIcon} className="successIcon" alt="Success" />

            {props.data.action == 'partnerInformation' ? (
              <h6 className="Heading secondaryFont">
                {props.partnerName}
                {props.data.content}
              </h6>
            ) : (
              <h6 className="Heading secondaryFont">{props.data.content}</h6>
            )}
            {props.data.action == 'hmUser' ? (
              <Button
                className="Button"
                onClick={() => {
                  props.getHmUsersList();
                }}
                style={{ fontSize: '14px' }}
              >
                Continue to H&M Group users
              </Button>
            ) : (
              ''
            )}
            {props.data.action == 'partnerProfiles' ? (
              <Button
                className="Button"
                onClick={() => {
                  props.getPartnersList();
                }}
              >
                Continue to partner profiles
              </Button>
            ) : (
              ''
            )}
            {props.data.action == 'partnerInformation' ? (
              <Button
                className="Button"
                onClick={() => {
                  props.loadPartner();
                }}
              >
                Continue to partner information
              </Button>
            ) : (
              ''
            )}
            {props.data.action == 'partnerUser' ? (
              <Button
                className="Button"
                onClick={() => {
                  props.getPartnerUser();
                }}
              >
                Continue to users
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
