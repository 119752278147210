const intialState = {
  filterData: {},
};

const campaignFilterReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'campaign/updateFilterCampaign':
      return {
        ...state,
        selectedCampaignFilter: action.payload,
      };
    case 'campaign/getFilterCampaign':
      return state;
    default:
      return state;
  }
};

export default campaignFilterReducer;
