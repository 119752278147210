import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Global/Popup/popup.scss';
import './index.scss';

const NotificationArticles = (props: any) => {
  const notificationArticles = props.notificationArticles;
  const categoryId = props.categoryId;
  const businessModelId = props.businessModelId;
  const pageType = props.pageType;
  const portfolioBrand = props.portfolioBrand;

  return (
    <div className="popup-backdrop notificationArticles">
      <div className="addUser-popup">
        <div className="addUser-popup--header Popup-header">
          <h6 className="Heading">
            {props.title}{' '}
            <span
              onClick={() => {
                props.closePopup();
              }}
              data-testid="notificationClosePopup"
            >
              <i className="icon-Close Popup-close"></i>
            </span>
          </h6>
        </div>

        <div className="addUser-popup--body">
          <div className="addUser-popup--body--notification">
            {notificationArticles.length === 0 && <>No results found</>}
            {notificationArticles?.length > 0 && (
              <>
                <Row className="gx-4 gy-4">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {notificationArticles?.map((res: any) => {
                      return (
                        <a
                          href={`/article${res.url}?categoryId=${categoryId}&businessModelId=${businessModelId}&pageType=${pageType}&portfolioBrand=${portfolioBrand}`}
                          key={res?.id}
                        >
                          <div
                            style={{
                              backgroundColor: 'white',
                              borderRadius: 20,
                              padding: 20,
                              marginBottom: 20,
                              marginTop: 20,
                              boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.1)',
                            }}
                          >
                            <h6>{res?.catHeading}</h6>
                            <p>{res?.heading}</p>
                          </div>
                        </a>
                      );
                    })}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationArticles;
