const intialState = {
  partnerProfiles: [],
};

const partnerProfilesReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'partner/updatePartnerProfiles':
      return {
        ...state,
        partnerProfiles: action.payload,
      };
    case 'partner/getPartnerProfiles':
      return state;
    default:
      return state;
  }
};

export default partnerProfilesReducer;
