const intialState = {
  partnerEntity: {},
};

const partnerEntityReducer = (state = intialState, action: any) => {
  switch (action.type) {
    case 'partner/updatePartnerEntity':
      return {
        ...state,
        partnerEntity: action.payload,
      };
    case 'partner/getPartnerEntity':
      return state;
    default:
      return state;
  }
};

export default partnerEntityReducer;
