import loginReducer from './loginReducer';
import userReducer from './userReducer';
import userTypeReducer from './userTypeReducer';
import partnerReducer from './partnerReducer';
import partnerProfilesReducer from './partnerProfilesReducer';
import partnerEntityReducer from './partnerEntityReducer';
import partnerFilterReducer from './partnerFilterReducer';
import campaignFilterReducer from './campaignFilterReducer';
import campaignDownloadReducer from './campaignDownloadReducer';
import learnReducer from './learnReducer';

import { combineReducers } from 'redux';
import currentPartnerUserReducer from './currentPartnerUserReducer';

const store = combineReducers({
  login: loginReducer,
  user: userReducer,
  userType: userTypeReducer,
  partner: partnerReducer,
  partnerProfiles: partnerProfilesReducer,
  selectedPartnerEntity: partnerEntityReducer,
  selectedPartnerFilter: partnerFilterReducer,
  selectedCampaignFilter: campaignFilterReducer,
  selectedCampaignDownload: campaignDownloadReducer,
  currentPartnerUser: currentPartnerUserReducer,
  learn: learnReducer,
});

export default store;
