import { useState, useEffect } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import './index.scss';
import ExcelImage from '../../assets/images/fileImage.png';
import { Row, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import removeIcon from '../../assets/images/icons/removeIcon.png';
import expand from '../../assets/images/expand.png';
import minimize from '../../assets/images/minimize.png';
import rejectIcon from '../../assets/images/rejectIcon.png';
import acceptIcon from '../../assets/images/acceptIcon.png';
import autoDeleteIcon from '../../assets/images/icons/autoDeleteIcon.png';
import moment from 'moment';
import commentIcon from '../../assets/images/icons/commentIcon.png';
import Button from '../Shared/Button';
import downloadIcon from '../../assets/images/downloadIcon.png';
import downloadIconHover from '../../assets/images/downloadIconHover.png';
import Loader from '../../components/Shared/Loader';
import { downloadAssets } from '../../services/CampaignInternalService';

const Product = (props: any) => {
  const [imageUrl, setImageUrl] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [zoomImagePathUrl, setZoomImagePathUrl] = useState('');
  const [userType, setUserType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userTypeData = useSelector((state: RootStateOrAny) => state.userType);
  const partnerDetail = useSelector((state: RootStateOrAny) => state.partner);
  const [deletionDate, setDeletionDate] = useState<Date | null>(null);
  const [showAutoDelete, setShowAutoDelete] = useState(false);
  const [fileName, setFileName] = useState('');
  const [iconSrc, setIconSrc] = useState(downloadIcon);
  const textEllipsisMaxLength = 20;
  let usertype: any = window.sessionStorage.getItem('userType');
  const goLiveText = 'Preferred Go-live';
  const goLiveDate = props.campaign?.goLiveDate && moment(props.campaign?.goLiveDate).format('DD/MM/YYYY');

  const handleMouseEnter = () => {
    setIconSrc(downloadIconHover);
  };

  const handleMouseLeave = () => {
    setIconSrc(downloadIcon);
  };

  useEffect(() => {
    if (userTypeData.userType) {
      setUserType(userTypeData.userType);
      loadImages(userTypeData.userType);
    } else {
      setUserType(usertype);
      loadImages(usertype);
    }
  }, [props.campaign.rowKey]);

  const loadImages = (usertype: string) => {
    let partnerId: any = '';

    if (usertype == 'HmUser') {
      partnerId = props.campaign.partnerId;
      setFileName(props.campaign?.uniqueFileName);
    } else {
      setFileName(props.campaign?.fileName);
      if (Object.keys(partnerDetail.partner).length !== 0) {
        partnerId = partnerDetail.partner.partnerId;
      } else {
        partnerId = window.sessionStorage.getItem('partnerId');
      }
    }

    let imagePath: string =
      props.campaign.uriroot + '/' + partnerId + '/' + props.campaign.uri + `?timestamp=${new Date().getTime()}`;
    setImageUrl(props.campaign.isImage ? imagePath : ExcelImage);
    let imageZoomPath: string =
      props.campaign.uriroot + '/' + partnerId + '/' + props.campaign.uri + `?timestamp=${new Date().getTime()}`;
    setZoomImagePathUrl(props.campaign.isImage ? imageZoomPath : ExcelImage);
  };

  const openModal = () => {
    setIsModalVisible(true);
    props.handleExpand && props.handleExpand(props.campaign?.brand, props.campaign?.rowKey);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  // const handleMinimize = () => {
  //   closeModal();
  //   props.clearSelectedAssets();
  // };

  const handleAccept = () => {
    props.handleAccept(props.campaign?.brand, 'Approved');
    closeModal();
  };

  const handleReject = () => {
    props.rejectReason(props.campaign?.brand, 'Rejected');
    closeModal();
  };

  // const handleDownload = () => {
  //   closeModal();
  //   props.handleDownload(props?.campaign);
  // };

  const handleDownload = () => {
    const asset = props?.campaign;
    closeModal();
    setShowLoader(true);
    const assetFileName = asset?.partnerId + '/' + asset?.uri;
    downloadAssets(assetFileName)
      .then(function (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setShowLoader(false);
      });
  };

  const handleReUpload = () => {
    props.handleReUpload(props?.campaign);
  };

  useEffect(() => {
    if (props?.campaign?.updatedOn) {
      const calculateDeletionDate = () => {
        const updatedOnDate = new Date(props?.campaign?.updatedOn);
        const deletionDate = new Date(
          updatedOnDate.getFullYear(),
          updatedOnDate.getMonth() + 18,
          updatedOnDate.getDate(),
        );

        return deletionDate;
      };

      const calculateNotificationDate = (deletionDate) => {
        const notificationDate = new Date(
          deletionDate.getFullYear(),
          deletionDate.getMonth() - 1,
          deletionDate.getDate(),
        );

        return notificationDate;
      };

      const deletionDateValue = calculateDeletionDate();
      setDeletionDate(deletionDateValue);

      const notificationDate = calculateNotificationDate(deletionDateValue);
      const today = new Date();
      console.log(new Date(today.toDateString()) + ' ' + new Date(notificationDate.toDateString()));

      setShowAutoDelete(new Date(today.toDateString()) >= new Date(notificationDate.toDateString()));
    }
  }, [props?.campaign?.updatedOn]);

  const handleExpand = props.isExpand ? openModal : undefined;

  return (
    <div className={props.className} key={props.campaign?.rowKey}>
      {props.deleteAsset && (
        <div
          className="ProductCard-delete"
          data-testid="deleteAsset"
          onClick={() => props.deleteAsset(props.campaign?.rowKey, props.campaign?.status)}
          data-title="Remove"
        ></div>
      )}

      <div className={`ProductCard-top ${props.isExpand ? 'isExpand' : ''}`} onClick={handleExpand}>
        <img alt={fileName} className="ProductCard-image" src={imageUrl} />
        <div className="ProductCard-content">
          <Row>
            <Col xs={5} lg={5}>
              <p className="u-bold">Asset Type</p>
            </Col>
            <Col xs={7} lg={7}>
              {props.campaign?.assetType && (
                <p
                  className={props.campaign?.assetType?.length >= textEllipsisMaxLength ? 'text-ellipsis' : ''}
                  data-tooltipText={props.campaign?.assetType}
                >
                  <span>{props.campaign?.assetType}</span>
                </p>
              )}
            </Col>
          </Row>
          {props.campaign?.format && (
            <Row>
              <Col xs={5} lg={5}>
                <p className="u-bold">Format</p>
              </Col>
              <Col xs={7} lg={7}>
                <p>{props.campaign?.format}</p>
              </Col>
            </Row>
          )}
          {props.campaign?.concept && (
            <Row>
              <Col xs={5} lg={5}>
                <p className="u-bold">Concept</p>
              </Col>
              <Col xs={7} lg={7}>
                <p>{props.campaign?.concept}</p>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={5} lg={5}>
              <p className="u-bold">Brand</p>
            </Col>
            <Col xs={7} lg={7}>
              {props.campaign?.brand && (
                <p
                  className={props.campaign?.brand?.length >= textEllipsisMaxLength ? 'text-ellipsis' : ''}
                  data-tooltipText={props.campaign?.brand}
                >
                  <span>{props.campaign?.brand}</span>
                </p>
              )}
            </Col>
          </Row>
          {props.campaign?.market && (
            <Row>
              <Col xs={5} lg={5}>
                <p className="u-bold">Market</p>
              </Col>
              <Col xs={7} lg={7}>
                <p>{props.campaign?.market}</p>
              </Col>
            </Row>
          )}

          {fileName && (
            <Row>
              <Col xs={5} lg={5}>
                <p className="u-bold">File Name</p>
              </Col>
              <Col xs={7} lg={7}>
                <p
                  className={fileName?.length >= textEllipsisMaxLength ? 'text-ellipsis' : ''}
                  data-tooltipText={fileName}
                >
                  <span>{fileName}</span>
                </p>
              </Col>
            </Row>
          )}

          {goLiveDate && (
            <Row>
              <Col xs={5} lg={5}>
                <p className={goLiveText?.length >= 15 ? 'text-ellipsis ' : ''} data-tooltipText={goLiveText}>
                  <span className="u-bold">{goLiveText}</span>
                </p>
              </Col>
              <Col xs={7} lg={7}>
                <p>{goLiveDate}</p>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {props.isDownload && (
        <div
          className="ProductCard-download"
          onClick={handleDownload}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={iconSrc} alt={'Download'} className="download-icon" />
        </div>
      )}
      {props.onSelect && (
        <>
          {/* <div className="ProductCard-expand" onClick={openModal}>
              <img src={expand} alt={'Expand'} className="expand-icon" />
            </div> */}
          {props.assetCount > 1 && (
            <div className="ProductCard-checkbox">
              <div className="checkbox-container">
                <input
                  key={props.campaign?.rowKey}
                  type="checkbox"
                  id={props.campaign.rowKey}
                  name="assets"
                  data-testid="checkboxAsset"
                  className="Checkbox-input hidden-checkbox"
                  checked={props.isSelected}
                  onChange={props.onSelect}
                />
                <label htmlFor={props.campaign.rowKey} className="checkbox-label">
                  <span className="checkbox-custom"></span>
                </label>
              </div>
            </div>
          )}
        </>
      )}

      {showAutoDelete && props?.campaign?.status === 'Approved' && (
        <div className="autoDelete">
          <img src={autoDeleteIcon} alt={'Delete'} className="delete-icon" />
          <span className="tooltip">
            This asset will automatically be deleted on {moment(deletionDate).format('YYYY-MM-DD')}.
          </span>
        </div>
      )}
      {props.campaign.rejectreason && (
        <span data-tooltip={props.campaign.rejectreason}>
          <img src={commentIcon} alt="!" className="commentIcon" />
        </span>
      )}

      <Modal show={isModalVisible} onHide={closeModal} centered className="expandPopup">
        <Modal.Body>
          {/* <div className="ProductCard-minimize" onClick={handleMinimize}>
            <img src={minimize} alt="Close" />
          </div> */}
          <div className="imageContainer">
            <img src={zoomImagePathUrl} alt={fileName} className="modal-image" />
          </div>
          {fileName && <p className="mb-4 fileNameText">{fileName}</p>}
          <div className="modal-content">
            <h6 className="mb-3 mt-4">Details</h6>
            <Row>
              <Col xs={3} lg={3}>
                <p className="u-bold">Asset Type</p>
              </Col>
              <Col xs={9} lg={9}>
                {props.campaign?.assetType && <p>{props.campaign?.assetType}</p>}
              </Col>
            </Row>
            <Row>
              <Col xs={3} lg={3}>
                <p className="u-bold">Brand</p>
              </Col>
              <Col xs={9} lg={9}>
                {props.campaign?.brand && <p>{props.campaign?.brand}</p>}
              </Col>
            </Row>
            {props.campaign?.format && (
              <Row>
                <Col xs={3} lg={3}>
                  <p className="u-bold">Format</p>
                </Col>
                <Col xs={9} lg={9}>
                  <p>{props.campaign?.format}</p>
                </Col>
              </Row>
            )}
            {props.campaign?.concept && (
              <Row>
                <Col xs={3} lg={3}>
                  <p className="u-bold">Concept</p>
                </Col>
                <Col xs={9} lg={9}>
                  <p>{props.campaign?.concept}</p>
                </Col>
              </Row>
            )}
            {props.campaign?.market && (
              <Row>
                <Col xs={3} lg={3}>
                  <p className="u-bold">Market</p>
                </Col>
                <Col xs={9} lg={9}>
                  <p>{props.campaign?.market}</p>
                </Col>
              </Row>
            )}
            {goLiveDate && (
              <Row>
                <Col xs={3} lg={3}>
                  <p className="u-bold">{goLiveText}</p>
                </Col>
                <Col xs={9} lg={9}>
                  <p>{goLiveDate}</p>
                </Col>
              </Row>
            )}

            {props.campaign.rejectreason && <p className="rejectReasonText">{props.campaign.rejectreason}</p>}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {usertype == 'HmUser' && props?.campaign?.status === 'Received' && (
            <Button className="Button Button--outlined Button--iconFirst" onClick={handleAccept}>
              Approve
              {/* <img className="ms-2 reject-icon" src={acceptIcon} alt={'Accept'} /> */}
            </Button>
          )}

          <Button className="Button Button--outlined Button--iconFirst" onClick={handleDownload}>
            Download
          </Button>

          {usertype == 'HmUser' && props?.campaign?.status === 'Received' && (
            <Button className="Button Button--transparentBlack discardButton" onClick={handleReject}>
              Reject
              {/* <img className="ms-2 reject-icon" src={rejectIcon} alt={'Reject'} /> */}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {props.handleReUpload && (
        <div className="text-center">
          <Button className="mt-2 mb-3 Button Button--outlined Button--iconFirst" onClick={handleReUpload}>
            Reupload
          </Button>
        </div>
      )}
      {showLoader && <Loader />}
    </div>
  );
};
export default Product;
