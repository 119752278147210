import React, { useState, useRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './index.scss';
import Button from '../../components/Shared/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Hero from '../../components/Hero';
import Select from 'react-select';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { getInvoiceDetail, updateInvoice } from '../../services/EInvoiceService';
import Loader from '../../components/Shared/Loader';
import moment from 'moment';
import { exportToCSVInvoiceLineItems, getCurrencySymbol, formatNumber } from '../../helpers/utilities';
import PermissionsGate from '../../components/PermissionsGate';
import { SCOPES } from '../../helpers/permission-maps';

const DropShipInvoice = () => {
  let history = useHistory();
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceDetailData, setInvoiceDetailData] = useState<any>({});
  const { invoiceHeader, invoiceLineItems } = invoiceDetailData;
  const [show, setShow] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [selectedExportInvoice, setSelectedExportInvoice] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [showLoader, setShowLoader] = useState(false);
  let search = window.location.search;
  let par = new URLSearchParams(search);
  const invoiceId = par?.get('invoiceId');
  const vatAmounts = invoiceHeader && invoiceHeader?.vatAmounts ? invoiceHeader?.vatAmounts : [];
  const [notifyText, setNotifyText] = useState('');
  const [accept, setAccept] = useState(false);
  const reExchangeRate = /^\d+(\.\d+)?$/;
  const currencySymbol = getCurrencySymbol(invoiceHeader?.invoiceCurrency);

  const hasVatRate = vatAmounts.some((vat) => vat?.vatRate !== 0);

  const breakDownColumns = [
    hasVatRate && { title: 'VAT %', key: 'vatRate', className: 'ml-60', sizes: { xs: 3, sm: 3, md: 3 } },
    {
      title: 'Net Amount',
      key: 'netAmount',
      className: hasVatRate ? 'ml-20' : 'ml-40',
      sizes: hasVatRate ? { xs: 3, sm: 3, md: 3 } : { xs: 6, sm: 6, md: 6 },
    },
    { title: 'VAT Amount', key: 'vatAmount', className: 'ml-20', sizes: { xs: 3, sm: 3, md: 3 } },
    { title: 'Total', key: 'grossAmount', className: 'ml-20', sizes: { xs: 3, sm: 3, md: 3 } },
  ].filter(Boolean);

  const breakDownHeadingStyle = !hasVatRate ? { marginBottom: '0px' } : {};

  useEffect(() => {
    if (invoiceId) {
      setShowLoader(true);
      getInvoiceDetail(invoiceId)
        .then(function (response: any) {
          setShowLoader(false);
          const invoiceDetailData = response?.data;
          const partnerInvoiceDate = invoiceDetailData?.invoiceHeader?.partnerInvoiceDate;
          if (partnerInvoiceDate) {
            setInvoiceDate(new Date(partnerInvoiceDate));
          }
          setInvoiceDetailData(invoiceDetailData);
        })
        .catch(function (error) {
          setShowLoader(false);
          console.log(error);
        });
    }
  }, [invoiceId]);

  const handleClose = () => {
    setShow(false);
    setAccept(false);
  };
  const handleShow = () => {
    setAccept(true);
    setShow(true);
  };
  const handleCloseReject = () => {
    setShowReject(false);
    setError(false);
  };
  const handleOk = () => {
    if (invoiceHeader?.disputeReason?.trim()) {
      setShow(true);
      setShowReject(false);
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleShowReject = () => {
    setAccept(false);
    setShowReject(true);
  };

  const showDisplayLineItems = () => {
    history.push('/invoice-listing/invoice/display-line-items?invoiceId=' + invoiceId);
  };

  const exportInvoiceList = [
    { value: 'downloadPDF', label: 'Download PDF' },
    { value: 'downloadExcel', label: 'Download Excel' },
  ];

  const generatePDF = async () => {
    setShowLoader(true);
    const input = inputRef.current;
    if (!input) {
      console.error('Input element not found.');
      return null;
    }

    const hiddenElements = Array.from(input.querySelectorAll('.printHidden')) as HTMLElement[];
    const showElements = Array.from(input.querySelectorAll('.printShow')) as HTMLElement[];
    const showElementsInline = Array.from(input.querySelectorAll('.printShowInline')) as HTMLElement[];

    hiddenElements.forEach((element) => {
      element.style.display = 'none';
    });

    showElements.forEach((element) => {
      element.style.display = 'block';
    });

    showElementsInline.forEach((element) => {
      element.style.display = 'inline-block';
    });

    try {
      const canvas = await html2canvas(input, { scale: 1 });

      hiddenElements.forEach((element) => {
        element.style.display = '';
      });
      showElements.forEach((element) => {
        element.style.display = '';
      });

      showElementsInline.forEach((element) => {
        element.style.display = '';
      });

      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 10;
      heightLeft -= pageHeight;

      const doc = new jspdf('p', 'mm');
      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');

      while (heightLeft >= 0) {
        position = pageHeight - heightLeft;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
      }
      setShowLoader(false);
      return doc;
    } catch (error) {
      console.error('Error generating PDF:', error);
      setShowLoader(false);
      throw error;
    }
  };

  const handleExportInvoice = async (selectedOption: any) => {
    const selectedExportValue = JSON.parse(JSON.stringify(selectedOption))?.value;
    if (selectedExportValue === 'downloadPDF') {
      try {
        const generatedPDF = await generatePDF();

        if (!generatedPDF) {
          console.error('Failed to generate PDF.');
          return;
        }

        generatedPDF.save(invoiceId + '.pdf');
      } catch (error) {
        console.error('Error:', error);
      }
    } else if (selectedExportValue === 'downloadExcel') {
      exportToCSVInvoiceLineItems(invoiceLineItems, invoiceId);
    }
  };

  const handleComments = (event: any) => {
    const { name, value } = event.target;
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
    setInvoiceDetailData((prevState) => ({
      ...prevState,
      invoiceHeader: {
        ...prevState.invoiceHeader,
        [name]: value,
      },
    }));
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setInvoiceDetailData((prevState) => ({
      ...prevState,
      invoiceHeader: {
        ...prevState.invoiceHeader,
        [name]: value.trim(),
      },
    }));
  };

  const handleVATMarketCurrencyChange = (value) => {
    const re = /^\d*\.?\d*$/;

    if (value === '' || re.test(value)) {
      const updatedInvoiceData = { ...invoiceDetailData };
      const vatAmount = updatedInvoiceData?.invoiceHeader?.invoiceAmount?.headerVatAmount;
      const exchangeRateValue = value / vatAmount;
      updatedInvoiceData.invoiceHeader.invoiceAmount.vatAmountInMarketCurrency = value;
      const roundedExchangeRate = parseFloat(exchangeRateValue?.toFixed(4));

      if (reExchangeRate.test(value)) {
        updatedInvoiceData.invoiceHeader.invoiceAmount.vatExchangeRate = roundedExchangeRate;
      } else {
        updatedInvoiceData.invoiceHeader.invoiceAmount.vatExchangeRate = 0;
      }

      setInvoiceDetailData(updatedInvoiceData);
    }
  };

  const handleInvoiceDate = (name: any, date: any) => {
    const partnerInvoiceDate = moment(date).format('YYYY-MM-DD');
    setInvoiceDate(new Date(date));
    setInvoiceDetailData((prevState) => ({
      ...prevState,
      invoiceHeader: {
        ...prevState.invoiceHeader,
        [name]: partnerInvoiceDate,
      },
    }));
  };

  const handleAccept = async (statusValue) => {
    const formData = new FormData();
    const partnerInvoiceDateFormat = moment(invoiceDate).format('YYYY-MM-DD');
    invoiceDetailData.invoiceHeader = {
      ...invoiceDetailData.invoiceHeader,
      status: statusValue,
      partnerInvoiceDate: invoiceDetailData.invoiceHeader.partnerInvoiceDate || partnerInvoiceDateFormat,
      disputeReason: accept ? '' : invoiceDetailData.invoiceHeader.disputeReason,
      disputed: accept ? false : true,
    };

    const { invoiceId, partnerInvoiceReference, partnerInvoiceDate, status, disputeReason, invoicePdf } =
      invoiceDetailData.invoiceHeader;
    const { vatAmountInMarketCurrency, vatExchangeRate } = invoiceDetailData?.invoiceHeader?.invoiceAmount;

    const submitData = {
      invoiceId,
      partnerInvoiceReference,
      partnerInvoiceDate,
      status,
      disputeReason,
      invoicePdf,
      vatAmountInMarketCurrency,
      vatExchangeRate,
    };

    const jsonData = JSON.stringify(submitData);

    try {
      const generatedPDF = await generatePDF();

      if (!generatedPDF) {
        console.error('Failed to generate PDF.');
        return;
      }
      const pdfBlob = generatedPDF.output('blob');
      formData.append('Pdf', pdfBlob, 'invoice.pdf');
    } catch (error) {
      console.error('Error:', error);
    }

    formData.append('Data', jsonData);
    setShow(false);

    setShowLoader(true);
    updateInvoice(formData)
      .then(function (data) {
        setShowLoader(false);
        if (statusValue === 'New') {
          setNotifyText('Save as draft successfully');
        } else {
          setNotifyText(data);
        }
        setTimeout(() => {
          setNotifyText('');
        }, 7000);
      })
      .catch(function (error) {
        setShowLoader(false);
        console.log(error);
      });
  };

  const handleSaveDraft = () => {
    handleAccept('New');
  };

  return (
    invoiceDetailData && (
      <PermissionsGate scopes={[SCOPES.canAccessDropShipInvoice]} redirect={true} canAccessDropShipInvoice={true}>
        <div>
          <Container fluid="xl" className="increase-font-size dropshipInvoicePage" ref={inputRef}>
            <ul className="Breadcrumb mt-4 mb-1 printHidden">
              <li className="Breadcrumb-item">
                <div
                  onClick={() => {
                    history.push('/invoice-listing');
                  }}
                  className="Breadcrumb-link"
                >
                  Invoicing
                </div>
              </li>
              <li className="Breadcrumb-item">
                <span className="Breadcrumb-current">Invoice</span>
              </li>
            </ul>
            <h1 className="Heading Heading--h2">Invoice</h1>
            <div className="drop-ship-invoice mt-5">
              <Row>
                <Col xs={4} sm={4} md={4}>
                  <Row>
                    <Col xs={7} sm={7} md={7}>
                      <strong>Invoice Date</strong>
                      {invoiceHeader?.status === 'Accepted' ? (
                        <p className="printHidden">{moment(invoiceDate).format('DD MMM yyyy')}</p>
                      ) : (
                        <div className="printHidden">
                          <DatePicker
                            className="form-control invoiceDate mb-3"
                            selected={invoiceDate}
                            minDate={new Date(invoiceDetailData?.invoiceHeader?.invoiceGenerationDate)}
                            onChange={(date) => handleInvoiceDate('partnerInvoiceDate', date)}
                            closeOnScroll={true}
                            showMonthDropdown
                            useShortMonthInDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd MMM yyyy"
                          />
                        </div>
                      )}
                      <p className="printShow">{moment(invoiceDate).format('DD MMM yyyy')}</p>
                      <strong>Invoice Number</strong>
                      {invoiceHeader?.status === 'Accepted' ? (
                        <p className="printHidden">{invoiceDetailData?.invoiceHeader?.partnerInvoiceReference}</p>
                      ) : (
                        <input
                          autoComplete="off"
                          type="text"
                          name="partnerInvoiceReference"
                          placeholder="Enter Invoice Number"
                          value={invoiceDetailData?.invoiceHeader?.partnerInvoiceReference}
                          onChange={handleInputChange}
                          maxLength={20}
                          className="form-control invoiceDate  mb-3 printHidden"
                        />
                      )}
                      <p className="printShow">{invoiceDetailData?.invoiceHeader?.partnerInvoiceReference}</p>
                      <strong>Reference</strong>
                      <p>{invoiceDetailData?.invoiceHeader?.invoiceId}</p>
                    </Col>
                  </Row>
                  <div>
                    <strong>Invoice Description</strong>
                    <p>
                      Invoice of products sold on hm.com between{' '}
                      {moment(invoiceHeader?.ordersFromDate).format('YYYY/MM/DD')} and{' '}
                      {moment(invoiceHeader?.ordersToDate).format('YYYY/MM/DD')}
                    </p>
                  </div>
                  <Row>
                    <strong>Delivered From</strong>
                    <p>{invoiceDetailData?.invoiceHeader?.shippedFrom}</p>

                    <strong>Delivered To</strong>
                    <p>{invoiceDetailData?.invoiceHeader?.shippedTo}</p>

                    <strong>Invoice Type</strong>
                    <p>{invoiceDetailData?.invoiceHeader?.invoiceType}</p>

                    <strong>Currency</strong>
                    <p>{invoiceDetailData?.invoiceHeader?.invoiceCurrency}</p>

                    <strong>Shop ID Mirakl</strong>
                    <p>{invoiceDetailData?.invoiceHeader?.shopid}</p>

                    {invoiceDetailData?.invoiceHeader?.disputed && (
                      <div className="printHidden">
                        <strong>Invoice Comment</strong>
                        <p>{invoiceDetailData?.invoiceHeader?.disputeReason}</p>
                      </div>
                    )}
                  </Row>
                </Col>
                <Col xs={8} sm={8} md={8}>
                  <Row>
                    <Col xs={6} sm={6} md={6}>
                      <p>
                        <strong>Buyer</strong>
                      </p>
                      <p>
                        <strong>
                          {invoiceHeader && invoiceHeader.recipient && invoiceHeader?.recipient?.companyName}
                        </strong>{' '}
                        <br />
                        {invoiceHeader && invoiceHeader?.recipient && invoiceHeader?.recipient?.invoiceAddress && (
                          <>
                            {invoiceHeader?.recipient?.invoiceAddress?.streetAddress1} <br />
                            {invoiceHeader?.recipient?.invoiceAddress?.streetAddress2 &&
                              `${invoiceHeader?.recipient?.invoiceAddress?.streetAddress2} <br />`}
                            {invoiceHeader?.recipient?.invoiceAddress?.postalCode}{' '}
                            {invoiceHeader?.recipient?.invoiceAddress?.city} <br />
                            {invoiceHeader?.recipient?.invoiceAddress?.country}
                          </>
                        )}
                      </p>
                      {invoiceHeader?.recipient?.companyVatNumber && (
                        <p>
                          <strong>VAT No.</strong> <br />
                          {invoiceHeader?.recipient?.companyVatNumber}
                        </p>
                      )}
                    </Col>
                    <Col xs={6} sm={6} md={6}>
                      <p>
                        <strong>Supplier</strong>
                      </p>
                      <p>
                        <strong>
                          {invoiceHeader && invoiceHeader.supplier && invoiceHeader?.supplier?.companyName}
                        </strong>{' '}
                        <br />
                        {invoiceHeader && invoiceHeader?.supplier && invoiceHeader?.supplier?.companyAddress && (
                          <>
                            {invoiceHeader?.supplier?.companyAddress?.streetAddress1} <br />
                            {invoiceHeader?.supplier?.companyAddress?.streetAddress2 &&
                              `${invoiceHeader?.supplier?.companyAddress?.streetAddress2} <br />`}
                            {invoiceHeader?.supplier?.companyAddress?.postalCode}{' '}
                            {invoiceHeader?.supplier?.companyAddress?.city} <br />
                            {invoiceHeader?.supplier?.companyAddress?.country}
                          </>
                        )}
                      </p>
                      {invoiceDetailData?.invoiceHeader?.supplier?.companyVatNumber && (
                        <p>
                          <strong>VAT No.</strong> <br />
                          {invoiceDetailData?.invoiceHeader?.supplier?.companyVatNumber.includes(',')
                            ? invoiceDetailData?.invoiceHeader?.supplier?.companyVatNumber
                                .split(',')
                                .map((vat, index) => (
                                  <React.Fragment key={index}>
                                    {vat?.trim()}
                                    <br />
                                  </React.Fragment>
                                ))
                            : invoiceHeader?.supplier?.companyVatNumber}
                        </p>
                      )}
                      {invoiceHeader?.supplier?.companyRegistrationNumber && (
                        <p>
                          <strong>Reg. No.</strong> <br />
                          {invoiceHeader?.supplier?.companyRegistrationNumber}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                      <Card body className="drop-ship-inovice-card">
                        <h6 className="mb-4">Breakdown</h6>
                        {/* <Row>
                        <Col xs={6} sm={6} md={6}>
                          <p>Net Amount</p>
                        </Col>
                        <Col xs={6} sm={6} md={6} className="text-end">
                          <p>{invoiceHeader?.invoiceAmount?.headerNetAmount}</p>
                        </Col>
                      </Row>
                      {vatAmounts.map((vat, index) => (
                        <div key={index}>
                          <Row className="vatTax">
                            <Col xs={6} sm={6} md={6}>
                              <p>VAT ({vat?.vatRate}%)</p>
                            </Col>
                            <Col xs={6} sm={6} md={6} className="text-end">
                              <p>{vat?.vatAmount}</p>
                            </Col>
                          </Row>
                        </div>
                      ))} */}
                        <Row>
                          {breakDownColumns.map((column, index) => (
                            <Col key={index} {...column.sizes} className={`text-end ${column.className}`}>
                              <p className="breakDownHeading" style={breakDownHeadingStyle}>
                                {column.title}
                              </p>
                              <div className="breakDownValue">
                                {vatAmounts?.map((vat, i) => (
                                  <div key={i}>
                                    <p>
                                      {column.key === 'vatRate'
                                        ? `${vat?.[column.key]}%`
                                        : formatNumber(vat?.[column.key])}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          ))}
                        </Row>

                        <Row className="pt-3 mt-3 mb-3 totalAmount">
                          <Col xs={3} sm={3} md={3} className="text-end ml-60">
                            <h6>Total</h6>
                          </Col>
                          <Col xs={3} sm={3} md={3} className="text-end ml-20">
                            <p>{formatNumber(invoiceHeader?.invoiceAmount?.headerNetAmount)}</p>
                          </Col>
                          <Col xs={3} sm={3} md={3} className="text-end ml-20">
                            <p>{formatNumber(invoiceHeader?.invoiceAmount?.headerVatAmount)}</p>
                          </Col>
                          <Col xs={3} sm={3} md={3} className="text-end ml-20">
                            <h6 className="totalAmountValue">
                              {invoiceHeader?.invoiceCurrency && invoiceHeader?.invoiceCurrency + ' '}{' '}
                              {formatNumber(invoiceHeader?.invoiceAmount?.headerGrossAmount)}
                            </h6>
                          </Col>
                        </Row>
                        <Button
                          className="Button Button--whiteBlack Button--arrow printHidden"
                          onClick={() => {
                            showDisplayLineItems();
                          }}
                        >
                          View Line Items
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                      <Card body className="drop-ship-inovice-card paymentDetails">
                        <h6 className="mb-4">Payment details</h6>
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <strong>Bank name</strong>
                          </Col>
                          <Col xs={6} sm={6} md={6} className="text-end">
                            {invoiceHeader?.supplier?.paymentDetails?.bankName}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <strong>Swift code</strong>
                          </Col>
                          <Col xs={6} sm={6} md={6} className="text-end">
                            {invoiceHeader?.supplier?.paymentDetails?.swiftBic}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <strong>IBAN</strong>
                          </Col>
                          <Col xs={6} sm={6} md={6} className="text-end">
                            {invoiceHeader?.supplier?.paymentDetails?.iban}
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col xs={7} sm={7} md={7}></Col>
                          <Col xs={5} sm={5} md={5} className="text-end">
                            <strong>
                              Payable{' '}
                              {invoiceDetailData?.invoiceHeader?.invoiceCurrency &&
                                invoiceDetailData?.invoiceHeader?.invoiceCurrency + ' '}
                              {invoiceDetailData?.invoiceHeader?.invoiceAmount?.headerGrossAmount &&
                                formatNumber(invoiceDetailData?.invoiceHeader?.invoiceAmount?.headerGrossAmount)}
                            </strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={7} sm={7} md={7}>
                            {!(
                              invoiceDetailData?.invoiceHeader?.invoiceAmount?.headerVatAmount === 0 ||
                              invoiceDetailData?.invoiceHeader?.invoiceAmount?.marketCurrency ===
                                invoiceDetailData?.invoiceHeader?.invoiceCurrency
                            ) ? (
                              <p className="vatLocal">
                                <span className="me-3">
                                  <strong className="me-2">
                                    {' '}
                                    VAT ({invoiceHeader?.invoiceAmount?.marketCurrency})
                                  </strong>
                                  {invoiceHeader?.status === 'Accepted' ? (
                                    <span className="printHidden">
                                      {formatNumber(invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency)}
                                    </span>
                                  ) : (
                                    <input
                                      autoComplete="off"
                                      value={invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency}
                                      type="text"
                                      placeholder=""
                                      maxLength={8}
                                      className="invoiceDate taxAmount printHidden"
                                      onChange={(e) => handleVATMarketCurrencyChange(e.target.value)}
                                    />
                                  )}
                                  <span className="printShowInline">
                                    {formatNumber(invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency)}
                                  </span>
                                </span>
                                <span>
                                  <strong>Exchange Rate</strong> {invoiceHeader?.invoiceAmount?.vatExchangeRate}
                                </span>
                              </p>
                            ) : null}
                          </Col>
                          <Col xs={5} sm={5} md={5} className="text-end">
                            <p className="mt-2">
                              Due {moment(invoiceHeader?.invoiceGenerationDate).add(30, 'days').format('YYYY-MM-DD')}
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <p className="mt-5">
                <i>
                  <strong>Please note:</strong> This is an invoice draft based on information from MIRAKL. This is not
                  to be considered as self-billing. As the supplier, you are responsible for reporting your VAT and
                  taxes according to the regulations in your country.{' '}
                </i>
              </p>
              <div className="mb-4 mt-5 drop-ship-invoice-footer printHidden">
                <Button
                  className="Button Button--outlined Button--iconFirst"
                  disabled={
                    invoiceHeader?.status === 'Accepted' ||
                    (!reExchangeRate.test(invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency) &&
                      !(invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency === ''))
                  }
                  onClick={handleSaveDraft}
                >
                  <span>Save as draft</span>
                </Button>

                <Button
                  className="Button Button--outlined Button--iconFirst"
                  disabled={
                    invoiceHeader?.status === 'Accepted' ||
                    !invoiceDetailData?.invoiceHeader?.partnerInvoiceReference ||
                    !invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency?.toString() ||
                    !reExchangeRate.test(invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency)
                  }
                  onClick={handleShow}
                >
                  Accept
                </Button>

                <Button
                  className="Button Button--outlined Button--iconFirst"
                  disabled={
                    invoiceHeader?.status === 'Accepted' ||
                    !invoiceDetailData?.invoiceHeader?.partnerInvoiceReference ||
                    !invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency?.toString() ||
                    !reExchangeRate.test(invoiceHeader?.invoiceAmount?.vatAmountInMarketCurrency)
                  }
                  onClick={handleShowReject}
                >
                  <span>Dispute</span>
                </Button>

                <Select
                  className="exportInvoice"
                  isClearable
                  isSearchable
                  value={selectedExportInvoice}
                  onChange={handleExportInvoice}
                  options={exportInvoiceList}
                  placeholder="Export"
                />
                <a href="mailto:partnersupport@hm.com">
                  <Button className="Button Button--outlined Button--iconFirst">
                    <span className="icon-Envelope"></span>
                    Contact H&M
                  </Button>
                </a>
              </div>
            </div>
            <div className="u-marginTlg"></div>
            <Modal show={show} onHide={handleClose} backdrop="static" centered className="invoicePopup">
              <Modal.Body>
                <div>
                  <h6>
                    {!accept && invoiceHeader?.disputeReason
                      ? `Send your invoice to H&M with the following comments`
                      : 'You will now send your invoice to H&M.'}
                  </h6>
                  {!accept && invoiceHeader?.disputeReason ? (
                    <p className="commentsData">
                      <i>{invoiceHeader?.disputeReason}</i>
                    </p>
                  ) : (
                    ''
                  )}
                  <p className="mt-5 mb-4">
                    <strong>Please confirm the following</strong>
                  </p>
                  <div className="confirmText">
                    <p>1. You have understood that you are still responsible for the content in this invoice.</p>
                    <p>2. You are responsible to report VAT and taxes according to regulations in your country.</p>
                    {!accept && invoiceDetailData?.invoiceHeader?.disputeReason ? (
                      <p>
                        3. Despite the comments added above, you accept sending this invoice to H&M. A red icon will
                        appear next to this invoice so you are reminded of your comments.
                      </p>
                    ) : null}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button className="Button Button--outlined Button--iconFirst" onClick={handleClose}>
                  Cancel
                </Button>
                <Button className="Button Button--iconFirst" onClick={() => handleAccept('Accepted')}>
                  {invoiceHeader?.disputeReason ? 'Accept & Send' : 'Accept & Send'}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showReject} onHide={handleCloseReject} backdrop="static" centered className="invoicePopup">
              <Modal.Body>
                <div className={error ? 'rejectPopup error' : 'rejectPopup'}>
                  <h6 className="mb-5">
                    Add comments{' '}
                    <label
                      className="tool"
                      data-tip="Example: The total value or total tax submitted in the invoice didn’t match the sum of the line items on the invoice"
                    >
                      <i className="icon-Info"></i>
                    </label>
                  </h6>
                  <textarea
                    maxLength={1000}
                    rows={5}
                    cols={70}
                    name="disputeReason"
                    value={invoiceHeader?.disputeReason}
                    required
                    onChange={handleComments}
                  />
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button className="Button Button--outlined Button--iconFirst" onClick={handleCloseReject}>
                  Cancel
                </Button>
                <Button className="Button Button--iconFirst" onClick={handleOk}>
                  Continue
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
          {showLoader ? <Loader /> : ''}
          {notifyText ? <div className="alert alert-secondary show-failed">{notifyText}</div> : null}
        </div>
      </PermissionsGate>
    )
  );
};

export default DropShipInvoice;
