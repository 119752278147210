import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './index.scss';
import Button from '../../../../components/Shared/Button';
import BreadcrumbLink from '../../../../components/Shared/BreadcrumbLink';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { getTrademarks, getSizeRegion } from '../../../../services/SizeService';
import { v4 as uuidv4 } from 'uuid';
import {
  deleteSize,
  getSize,
  updateSizeHapi,
  updateSize,
  addSizesToHapi,
  updateSizeState,
} from '../../../../services/SizeService';
import Loader from '../../../../components/Shared/Loader';
import MultiLevelDropdown from '../../../../components/MultiLevelDropdown';
import StaticData from '../../../../components/MultiLevelDropdown/StaticData';

let partnerId: string | null = '';
let entityId: any = '';
let categoryId: string = '';

const ImportManuallyEdit = (props) => {
  //Set editing id in state variable
  const [editingId, setEditingId] = useState(props.match.params.id);
  //Set size from state passed in props object
  const [size, setSize] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [trademarkList, setTrademarkList] = useState<any[]>([]);
  const [selectedTrademark, setSelectedTrademark] = useState<any[]>([]);
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState<any[]>([]);
  const [selectedProductType, setSelectedProductType] = useState<any>({});
  const [selectedProductTypeForApi, setSelectedProductTypeForApi] = useState<any[]>([]);
  const [sizesTableData, setSizesTableData] = useState<any[]>([
    {
      sortOrder: 1,
      sizeElements: [
        {
          sizeName: '',
          sizeRegionId: 'EUR',
        },
      ],
    },
  ]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [sizeRegionList, setSizeRegionList] = useState<any[]>([]);
  const [selectedSizeRegion, setSelectedSizeRegion] = useState<any[]>([]);

  partnerId = window.sessionStorage.getItem('partnerId');
  // entityId = window.sessionStorage.getItem('entityId');
  // categoryId = JSON.parse(window.sessionStorage.getItem('partnerDetail')!)?.categoryId;

  const history = useHistory();
  var customers = selectedCustomerGroup.map(function (val) {
    return val.value;
  });

  const formValues = {
    partnerHubId: editingId,
    trademarkId: selectedTrademark?.[0]?.value,
    customerGroups: customers,
    productTypes: selectedProductTypeForApi,
    sizes: sizesTableData,
  };

  const customerGroupList = [
    { value: '1', label: 'Boy(1-12yr)' },
    { value: '2', label: 'Girl (1-12yr)' },
    { value: '3', label: 'Infant (0-1yr)' },
    { value: '4', label: 'Men' },
    { value: '5', label: 'Woman' },
    { value: '6', label: 'Unisex' },
  ];
  const productTypeList = [
    { value: '1', label: 'Shoes' },
    { value: '2', label: 'Sneakers' },
    { value: '3', label: 'Heels' },
  ];

  const breadcrumbLinks = [
    {
      name: 'Size upload',
      path: '/sizes',
    },
    {
      name: 'Edit size',
      path: '/sizes/importmethod/manually',
    },
  ];

  const handleSend = () => {
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleCancel = () => {
    history.push('/sizes');
  };

  const handleDelete = () => {
    deleteSize(editingId).then((data) => {
      history.push('/sizes');
    });
  };

  useEffect(() => {
    const selectedRegion: any = [];
    sizesTableData?.[0].sizeElements.map((sizeRegion: any) => {
      if (JSON.parse(JSON.stringify(sizeRegion))?.sizeRegionId) {
        selectedRegion.push(JSON.parse(JSON.stringify(sizeRegion))?.sizeRegionId);
      }
    });
    setSelectedSizeRegion(selectedRegion);
  }, [sizesTableData]);

  useEffect(() => {
    getSize(editingId)
      .then((data) => {
        // console.log("data", data?.data.customerGroups)
        // console.log("data >>", data?.data)

        try {
          const selectedProductTypeValues = {};
          setSize(data?.data);

          //Loop sizes and add them to sizesTableData
          const sizeRoot: any[] = [];
          data?.data.sizes.map((size, index) => {
            const sizeElements: any[] = [];
            size.sizeElements.map((sizeElement) => {
              sizeElements.push({
                sizeName: sizeElement.sizeName,
                sizeRegionId: sizeElement.sizeRegionId,
              });
            });
            sizeRoot.push({
              sortOrder: index + 1,
              sizeElements: sizeElements,
            });
          });

          setSizesTableData(sizeRoot);

          data?.data.productTypes.map(
            ({ id, name }) => (selectedProductTypeValues[id] = { id: id, text: name, checked: true, isLeafNode: true }),
          );
          setSelectedProductType(selectedProductTypeValues);

          if (partnerId) {
            getTrademarks(partnerId).then((dataTrademark) => {
              const trademarks: any = [];
              dataTrademark.map((item: any) => {
                trademarks.push({ value: item.brandId, label: item.brandName });
              });
              setTrademarkList(trademarks);
              setSelectedTrademark(trademarks.find((item) => item.value === data?.data.trademarkId));
            });
          }

          getSizeRegion().then((data) => {
            const sizeRegions: any = [];
            data?.map((item: any) => {
              sizeRegions.push({ value: item.sizeRegionName, label: item.sizeRegionName });
            });
            setSizeRegionList(sizeRegions);
          });

          setSelectedCustomerGroup(
            data?.data.customerGroups.map((id, value) => {
              // console.log("id", id)
              // console.log("value", value)
              return { value: JSON.stringify(id), label: returnGroupName(JSON.stringify(id)) };
            }),
          );
        } catch (e) {
          setShowLoader(false);
        }
      })
      .then(() => {
        setShowLoader(false);
      });
  }, [editingId]);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setShowLoader(true);

      let guid = uuidv4();
      let cleanSizes: any[] = [];
      formValues.sizes.map((size) => {
        size.sizeElements.map((item) => {
          cleanSizes.push({
            region: item.sizeRegionId,
            name: item.sizeName,
          });
        });
      });

      // console.log("selectedCustomerGroup", selectedCustomerGroup)

      const customerGroups = selectedCustomerGroup.map((sweetItem) => {
        return sweetItem.value;
      });

      const productTypes = selectedProductTypeForApi;

      const productTypesForCustom = selectedProductTypeForApi.map((sweetItem) => {
        return sweetItem.name;
      });

      let sizeForHapi = {
        customerGroups: customerGroups,
        trademarkId: selectedTrademark['value'] ? selectedTrademark['value'] : selectedTrademark[0].value,
        productTypes: productTypes,
        sizes: sizesTableData,
        partnerHubId: formValues.partnerHubId,
      };

      let sizeForCustom = {
        customerGroup: customerGroups.toString(),
        trademark: selectedTrademark['value'] ? selectedTrademark['value'] : selectedTrademark[0].value,
        productType: productTypesForCustom.toString(),
        productSize: sizesTableData,
        guid: formValues.partnerHubId,
      };

      //Save to custom database
      updateSize(sizeForCustom, partnerId);

      //Save to HAPI
      updateSizeHapi(sizeForHapi).then((response: any) => {
        let message = '';
        let path = '/sizes';

        if (response?.data.reasonPhrase === 'Not Found') {
          addSizesToHapi(sizeForHapi).then((response: any) => {
            if (response?.data.reasonPhrase === 'Created') {
              updateSizeState(formValues.partnerHubId, true).then(function (response) {
                message = 'Sizes created and saved successfully.';
                history.push({ pathname: path, state: { message: message } });
              });
            } else {
              message = 'Error when uploading, please check the data.';
            }
          });
        } else if (response?.data.reasonPhrase === 'Created') {
          message = 'Sizes sent and saved successfully.';
        } else if (response?.data.reasonPhrase === 'OK') {
          message = 'Sizes sent and saved successfully.';
        }
        history.push({ pathname: path, state: { message: message } });
      });
    }
  }, [formErrors]);

  const returnGroupName = function (groupName) {
    switch (groupName) {
      case '0':
        return 'Boy(1-2yr)';
      case '1':
        return 'Boy(1-2yr)';
      case '2':
        return 'Girl (1-12yr)';
      case '3':
        return 'Infant (0-1yr)';
      case '4':
        return 'Men';
      case '5':
        return 'Woman';
      case '6':
        return 'Unisex';
      default:
        return '';
    }
  };

  // { value: '1', label: 'Boy(1-12yr)' },
  // { value: '2', label: 'Girl (1-12yr)' },
  // { value: '3', label: 'Infant (0-1yr)' },
  // { value: '4', label: 'Men' },
  // { value: '5', label: 'Woman' },
  // { value: '6', label: 'Unisex'}

  const validate = (values: any) => {
    const errors: any = {};
    const sizeData: any = [];
    let sizeTableError = 0;
    const lookup: any = {};

    // console.log("VALIDATE", values)

    values.sizes.map((sizesTable: any, sizeTableIndex: any) => {
      let sizeRegionId = '';
      let sizeName = '';
      const sizes: any = [];
      let sizeValidate = {};

      sizesTable.sizeElements.map((size: any, sizeIndex: any, self: any) => {
        if (!size.sizeRegionId) {
          sizeRegionId = 'Region is required!';
          sizeTableError++;
        }
        // else if (!(size.sizeRegio nId.toLowerCase() in lookup)) {
        //     lookup[size.sizeRegionId.toLowerCase()] = 1;
        //     sizeRegionId = '';
        // }
        // else
        // {
        //     sizeRegionId = 'Region is unique!';
        //     sizeTableError++;
        // }
        if (!size.sizeName) {
          sizeName = 'Size is required!';
          sizeTableError++;
        }
        sizes.push({ sizeRegionId: sizeRegionId, sizeName: sizeName });
      });
      sizeValidate = { sortOrder: sizeTableIndex, sizeElements: sizes };
      sizeData.push(sizeValidate);
    });
    if (values?.trademark?.length === 0) {
      errors.trademark = 'Brand is required!';
    }
    if (values?.customerGroups?.length === 0) {
      errors.customerGroup = 'Customer Group is required!';
    }
    if (values?.productTypes?.length === 0) {
      errors.productType = 'Product Type is required!';
    }
    if (sizeTableError > 0) {
      errors.sizesTableData = sizeData;
    }

    return errors;
  };

  const handleTrademark = (selectedOption: any) => {
    setSelectedTrademark(selectedOption);
  };

  const handleCustomerGroup = (selectedOption: any) => {
    setSelectedCustomerGroup(selectedOption);
  };

  const handleProductType = (selectedOption: any) => {
    // console.log("selectedOption", selectedOption)
    // const transformed = selectedOption.map(({ value, label }) => ({
    //     id: value,
    //     name: label
    // }));
    // console.log("transformed", transformed)
    // setSelectedProductType(selectedOption);
    setSelectedProductTypeForApi(selectedOption);
  };

  const handleChangeDropdownRegion = (index: any, event: any) => {
    const values = [...sizesTableData];
    values.map((_size, sizeIndex) => (values[sizeIndex].sizeElements[index].sizeRegionId = event.value));
    setSizesTableData(values);
  };

  const handleChangeInputSize = (regionDataIndex: any, index: any, event: any) => {
    const values = [...sizesTableData];
    values.map((sizesTable, regionIndex) => {
      if (regionDataIndex === regionIndex) {
        sizesTable.sizeElements[index].sizeName = event.target.value;
      }
    });
    setSizesTableData(values);
  };

  const handleAddSize = () => {
    const values = [...sizesTableData];
    const sortOrder = sizesTableData.length + 1;
    const sizeElementsData: any = [];

    values[0].sizeElements.map((sizeData: any, _index: any) => {
      sizeElementsData.push({ sizeName: '', sizeRegionId: sizeData.sizeRegionId });
    });

    setSizesTableData([...sizesTableData, { sortOrder: sortOrder, sizeElements: sizeElementsData }]);
  };

  const handleRemoveSize = (index: any) => {
    const values = [...sizesTableData];
    values.splice(index, 1);
    setSizesTableData(values);
  };

  const handleAddRegion = () => {
    const values = [...sizesTableData];
    values.map((_size, index) =>
      values[index].sizeElements.push({
        sizeName: '',
        sizeRegionId: '',
      }),
    );
    setSizesTableData(values);
  };

  const handleRemoveRegion = (regionIndex: any) => {
    const values = [...sizesTableData];
    const text = 'Are you sure you would like to delete this column?';
    if (window.confirm(text) === true) {
      values.map((_size, index) => values[index].sizeElements.splice(regionIndex, 1));
      setSizesTableData(values);
    }
  };

  return (
    <div className="Sizes Page-gradient">
      <Container fluid="xl">
        <Row>
          <Col xs={8} sm={8} md={8}>
            <div className="Hero">
              <h1 className="Heading Heading--h2" style={{ color: 'white' }}>
                Size edit
              </h1>
              <BreadcrumbLink links={breadcrumbLinks} />
            </div>
            <h3 style={{ color: 'white' }}>Edit sizes in the table</h3>
            <p>
              Please fill in all fields below. By clicking the “+” signs, additional sizes and regions can be added.
            </p>
          </Col>
        </Row>
        <form onSubmit={handleSend}>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <i style={{ fontSize: 12 }}>
                When in edit mode: Brand, Customer Group and Product Type are not allowed to be updated.
              </i>
            </Col>
          </Row>
          <Row>
            <Col xs={3} sm={3} md={3}>
              <h6 className="Heading--h7">Brand</h6>
              <Select
                isMulti={false}
                value={selectedTrademark}
                onChange={handleTrademark}
                options={trademarkList}
                placeholder="Please select"
                className={formErrors.trademark ? 'errorBorder' : ''}
                isDisabled={true}
              />
              <p className="error">{formErrors.trademark}</p>
            </Col>
            <Col xs={3} sm={3} md={3}>
              <h6 className="Heading--h7">Customer Group</h6>
              <Select
                isMulti={true}
                value={selectedCustomerGroup}
                onChange={handleCustomerGroup}
                options={customerGroupList}
                placeholder="Please select"
                className={formErrors.customerGroup ? 'errorBorder' : ''}
                isDisabled={true}
              />
              <p className="error">{formErrors.customerGroup}</p>
            </Col>
            <Col xs={3} sm={3} md={3}>
              <h6 className="Heading--h7">Product Type</h6>
              {/* <Select
                            isMulti={true}
                            value={selectedProductType}
                            onChange={handleProductType}
                            options={productTypeList}
                            placeholder="Please select"
                            className={formErrors.productType ? 'errorBorder':''}
                        /> */}
              <MultiLevelDropdown
                disabled={true}
                data={StaticData}
                selectedProductType={selectedProductType}
                handleProductType={handleProductType}
              />
              <p className="error">{formErrors.productType}</p>
            </Col>
          </Row>

          <Row>
            <Col xs={8} sm={8} md={8}>
              <br></br>
              <h6 className="Heading--h7">Profile sizes and translations</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} className="sizesTable">
              <table>
                <thead>
                  <tr>
                    {sizesTableData[0].sizeElements.map((region: any, regionIndex: any) => {
                      return (
                        <th key={'Region' + regionIndex}>
                          {regionIndex > 0 ? (
                            <div onClick={() => handleRemoveRegion(regionIndex)} className="regionDelete">
                              <i className="icon-Delete-bin"></i>
                            </div>
                          ) : (
                            <div>
                              <br></br>
                            </div>
                          )}
                          <Select
                            name="region"
                            isMulti={false}
                            value={[{ value: region.sizeRegionId, label: region.sizeRegionId }]}
                            isDisabled={regionIndex === 0 ? true : false}
                            onChange={(event) => handleChangeDropdownRegion(regionIndex, event)}
                            options={sizeRegionList.filter((item) => !selectedSizeRegion.includes(item.value))}
                            placeholder="Please select"
                            maxMenuHeight={100}
                            className={
                              formErrors.sizesTableData?.[0]?.sizeElements[regionIndex]?.sizeRegionId
                                ? 'regionDropdown errorBorder single-select'
                                : 'regionDropdown single-select'
                            }
                          />
                          {/* <input type="text" name="region" value={region.sizeRegionId} className={formErrors.sizesTableData?.[0]?.sizeElements[regionIndex]?.sizeRegionId ? "form-control sizesTextBox errorBorder" : "form-control sizesTextBox"} placeholder="" autoComplete="off" disabled={regionIndex === 0 ? true : false} onChange={event => handleChangeInputRegion(regionIndex, event)}/> */}
                          <p className="error">
                            {formErrors.sizesTableData?.[0]?.sizeElements[regionIndex]?.sizeRegionId}
                          </p>
                        </th>
                      );
                    })}
                    <th className="addRegion">
                      <span onClick={handleAddRegion}>
                        <i className="icon-Plus"></i> Add size region translation
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sizesTableData.map((region, regionIndex) => {
                    return (
                      <tr key={'Size' + regionIndex}>
                        {region.sizeElements.map((size: any, sizeIndex: any) => {
                          return sizeIndex == region.sizeElements.length - 1 ? (
                            <React.Fragment key={'SizeData' + sizeIndex}>
                              <td key={'SIzeName' + sizeIndex} className="text-center">
                                <input
                                  type="text"
                                  name="size"
                                  value={size.sizeName}
                                  className={
                                    formErrors.sizesTableData?.[regionIndex]?.sizeElements?.[sizeIndex]?.sizeName
                                      ? 'form-control sizesTextBox errorBorder'
                                      : 'form-control sizesTextBox'
                                  }
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(event) => handleChangeInputSize(regionIndex, sizeIndex, event)}
                                />
                                <p className="error">
                                  {formErrors.sizesTableData?.[regionIndex]?.sizeElements?.[sizeIndex]?.sizeName}
                                </p>
                              </td>
                              {regionIndex > 0 ? (
                                <td key={'sizeDelete' + sizeIndex}>
                                  <span className="sizeDelete">
                                    <i onClick={() => handleRemoveSize(regionIndex)} className="icon-Delete-bin"></i>
                                  </span>
                                </td>
                              ) : (
                                <td key={'sizeDelete' + sizeIndex}>
                                  <span className="sizeDelete"></span>
                                </td>
                              )}
                            </React.Fragment>
                          ) : (
                            <td key={'SIzeName' + sizeIndex} className="text-center">
                              <input
                                type="text"
                                name="size"
                                value={size.sizeName}
                                className={
                                  formErrors.sizesTableData?.[regionIndex]?.sizeElements?.[sizeIndex]?.sizeName
                                    ? 'form-control sizesTextBox errorBorder'
                                    : 'form-control sizesTextBox'
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(event) => handleChangeInputSize(regionIndex, sizeIndex, event)}
                              />
                              <p className="error">
                                {formErrors.sizesTableData?.[regionIndex]?.sizeElements?.[sizeIndex]?.sizeName}
                              </p>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>

          <Row>
            <span className="addSize">
              <span onClick={handleAddSize}>
                <i className="icon-Plus"></i> Add size
              </span>
            </span>
          </Row>
          <Row>
            <Col xs={2} sm={2} md={2}>
              <Button className="Button" onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            {/* <Col xs={2} sm={2} md={2}>  
                        <Button className="Button red" onClick={handleDelete}>Delete</Button>                        
                    </Col> */}
            <Col xs={3} sm={3} md={3}>
              <Button className="Button" onClick={handleSend}>
                Save and send to H&M
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
      {showLoader ? <Loader /> : <></>}
    </div>
  );
};

export default ImportManuallyEdit;
