import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './index.scss';

const PopUp = ({ show, key = '', handleClose, children, className = '' }) => {
  return (
    <Modal
      show={show}
      key={key}
      onHide={handleClose}
      backdrop="static"
      centered
      className={`popUpContainer ${className}`}
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default PopUp;
